import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Footer(props) {
  const SERVER_IMG_URL =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/';
  return (
    <footer className='column Footer'>
      <div className='column Footer__box'>
        <h1>{`Techful,\nBut Artistic`}</h1>
        <span className='Footer__info'>{`Call +82 1533-6017\nBusiness lisence number 171-86-02100\n45, Yangcheong 4-gill, Ochang-eup, Cheongwon-gu,\nCheongju-si, Chungcheongbuk-do, 28116, Rep. of KOREA`}</span>
        <nav className='Footer__nav'>
          <NavLink to={'/'}>Home</NavLink>
          <NavLink to={'/Service'}>Service</NavLink>
          <NavLink to={'/AboutUs'}>About us</NavLink>
          <NavLink to={'/News'}>News</NavLink>
          <NavLink to={'/ContactUs'}>Contact us</NavLink>
          <NavLink to={'/Account'}>Privacy Policy</NavLink>
        </nav>
        <img
          className='Footer__logo'
          alt='Arcane Inc.'
          src={`${SERVER_IMG_URL}ARCANE_WEB/renewal/Logo__full.webp`}
        />
      </div>
      <span className='Footer__copyright'>
        copyright © 2021 arcane Inc. All rights reserved.
      </span>
    </footer>
  );
}
