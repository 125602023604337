import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  HomePage,
  AboutPage,
  NewsPage,
  ContactPage,
  DownloadPage,
  AccountPage,
} from './pages';
import { AboutUs, ContactUs, Home, News, NewsInfo, Service } from './pages/v2';

import './scss/common.scss';

function App() {
  return (
    <div className='App'>
      <Router>
        <Routes>
          {/* <Route exact path='/' element={<HomePage />} />
          <Route path='/About' element={<AboutPage />} />
          <Route path='/News' element={<NewsPage />} />
          <Route path='/Contact' element={<ContactPage />} />
          <Route path='/Download' element={<DownloadPage />} /> */}
          <Route path='/Account' element={<AccountPage />} />
          <Route path='/' element={<Home />} />
          <Route path='/AboutUs' element={<AboutUs />} />
          <Route path='/News' element={<News />} />
          <Route path='/News/:title' element={<NewsInfo />} />
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/Service/*' element={<Service />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
