import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../common/Footer';

export default function Main(props) {
  const [service] = useState([
    {
      title: 'FAVI',
      title2: "'S SERVICE",
      contents: `Multi-Keyword Sound Sample Market.\nYou can find samples and pre-sets easily with more than 250 keywords.\nYou don't need to explore websites to find references. 'Favi' can make your music production more efficient.`,
    },
    // {
    //   title: 'CHART',
    //   title2: ' & MAGAZINE',
    //   contents: 'now preparing...',
    // },
  ]);
  return (
    <section className='column Service__main'>
      <nav className='Service__nav'>
        <a>OUR RELEASE</a>
        <a>OUR RELEASE</a>
        <a>OUR RELEASE</a>
      </nav>
      <h1 className='Service__title'>WHAT INFORMATION DO YOU WANT?</h1>
      <div className='column Service__list'>
        {service.map((sv) => (
          <NavLink
            key={`service_${sv.title}`}
            className='column ServiceCard'
            to={sv.title}
          >
            <h1>
              {sv.title}
              <strong>{sv.title2}</strong>
            </h1>
            <div className='ServiceCard__box'>
              <span>{sv.contents}</span>
            </div>
          </NavLink>
        ))}
      </div>
      <h3 className='Main__repeat'>
        NEW WAVE IN POP CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP
        CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP
        CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP CULTURE
      </h3>
    </section>
  );
}
