import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import useWindowSize from '../API/useWindowSize';

import Footer from './ITEM/Footer';
import Header from './ITEM/Header';
import onestore from '../img/onestore.png';

const useStyles = makeStyles((theme) => ({
  header: {
    height: '8vh',
    width: '90%',
    display: 'flex',
    position: 'fixed',
    top: '0',
    paddingLeft: '5%',
    paddingRight: '5%',
    zIndex: 5,
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
}));

const Home = ({ history }) => {
  const SERVER_IMG_URL =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/';
  const { width } = useWindowSize();
  const about_list = [
    {
      title: 'Tech',
      contents: '새로운 기술을 통해',
      contents2: '문화예술적 가치의 향상을 추구합니다.',
    },
    {
      title: 'Art',
      contents: '더 많은 사람들이 문화 예술적 가치를',
      contents2: '향유할 수 있기를 꿈꾸고 있습니다.',
    },
    {
      title: 'Artist',
      contents: '창작자들의 작품을 널리 알리고,',
      contents2: '창작에 집중할 수 있는 환경을 구축합니다.',
    },
    {
      title: 'Public',
      contents: '문화 예술 콘텐츠를 더 편리하고,',
      contents2: '쉽게 즐길 수 있는 환경을 만들어갑니다.',
    },
  ];
  const news_list = [
    {
      title: "대한민국 음악 큐레이션계의 '트랜드세터'",
      date: '2021. 04. 05',
      url: 'http://www.issuemaker.kr/news/articleView.html?idxno=33135',
    },
    {
      title: '[히든 챔피언] 천미르 아케인 주식회사 대표',
      date: '2021. 04. 05',
      url: 'https://m.post.naver.com/viewer/postView.nhn?volumeNo=31135428&memberNo=5899174',
    },
  ];

  const classes = useStyles();
  const [count, setCount] = useState(1);
  const controlsProps = {
    style: {
      left: '50%',
      top: 10,
      position: 'fixed',
      transform: 'translateX(-30%)',
    },
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  if (
    width < 500 ||
    getWindowDimensions().width < 500 ||
    getWindowDimensions().width <= getWindowDimensions().height
  ) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Header base={'./'} history={history} type={0} />
        {/* mobile main */}
        <div
          style={{
            display: 'block',
            height: '88vh',
            width: '100%',
            flexDirection: 'column',
            overflow: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '90%',
              padding: '5%',
              height: getWindowDimensions().width * 0.487,
              backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/Home/main.png')`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h3
              style={{
                margin: 0,
                color: 'white',
                textShadow: '1px 1px rgba(0,0,0,0.2)',
                fontFamily: 'NexaBold',
              }}
            >
              Make your Art Techful,
            </h3>
            <h3
              style={{
                margin: 0,
                color: 'white',
                textShadow: '1px 1px rgba(0,0,0,0.2)',
                fontFamily: 'NexaBold',
              }}
            >
              Make your Tech Artistic
            </h3>
            <h6
              style={{
                margin: 0,
                marginTop: 10,
                color: 'white',
                textShadow: '1px 1px rgba(0,0,0,0.2)',
              }}
            >
              IT 기술로 현실화된 예술 플랫폼의 새로운 시작
            </h6>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '7vh',
            }}
          >
            <h3 style={{ color: '#57477d', fontFamily: 'NexaBold' }}>
              About Arcane
            </h3>
            <h6 style={{ margin: 0, fontWeight: 'normal', color: '#444' }}>
              새로운 경험과 함께 더 쉬운 예술을 즐길 수 있게,
            </h6>
            <h6 style={{ margin: 0, fontWeight: 'normal', color: '#444' }}>
              더 편리해진 창작 활동, 대중에게 더 쉽게 다가가고 싶은 아티스트를
              위해!
            </h6>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: '10vh',
                paddingBottom: '5vh',
                flexWrap: 'wrap',
              }}
            >
              {about_list.map((item, i) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      width: getWindowDimensions().width / 2,
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      marginBottom: '7vh',
                    }}
                  >
                    <img
                      src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/${item.title}.png`}
                      style={{ width: 'auto', height: '3.5em' }}
                    />
                    <h4 style={{ margin: '15px 0px', fontFamily: 'NexaBold' }}>
                      {item.title}
                    </h4>

                    <h6
                      style={{
                        margin: 0,
                        fontWeight: 'normal',
                        color: '#444',
                        fontSize: 10,
                      }}
                    >
                      {item.contents}
                    </h6>
                    <h6
                      style={{
                        margin: 0,
                        marginTop: 3,
                        fontWeight: 'normal',
                        color: '#444',
                        fontSize: 10,
                      }}
                    >
                      {item.contents2}
                    </h6>
                  </div>
                );
              })}
            </div>
          </div>
          {/* FAVI 소개 */}
          <div
            style={{
              display: 'flex',
              width: '80%',
              height: getWindowDimensions().width * 0.45,
              paddingLeft: '10%',
              paddingRight: '10%',
              marginBottom: '0vh',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/Home/Favi.png')`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <h6
              style={{
                margin: 0,
                color: '#666',
                marginBottom: 10,
                fontFamily: 'NexaDemoBold',
              }}
            >
              Let's fall in Vibe
            </h6>
            <h5 style={{ margin: 0, color: 'black' }}>다중 키워드</h5>
            <h5 style={{ margin: 0, color: 'black' }}>음악 어플리케이션</h5>
            <img
              src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/logo.png`}
              style={{ width: 'auto', height: '1.3em', marginTop: 15 }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '90%',
              flexDirection: 'row',
              padding: '2vh 5%',
              alignItems: 'center',
              marginBottom: '3vh',
            }}
          >
            <a
              href='https://play.google.com/store/apps/details?id=com.mureka'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#bebef4',
                borderRadius: 30,
                padding: '9px 10px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/android.png`}
                style={{ width: 'auto', height: '0.9em', marginRight: 5 }}
              />
              <h6 style={{ margin: 0, color: 'white' }}>Play Store</h6>
            </a>
            <a
              href='https://apps.apple.com/kr/app/favi-%ED%8C%8C%EB%B9%84-%EA%B5%AC-%EB%AE%A4%EB%A0%88%EC%B9%B4-mureka/id1559270799'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#bebef4',
                borderRadius: 30,
                padding: '9px 10px',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 10,
              }}
            >
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/ios.png`}
                style={{ width: 'auto', height: '0.9em', marginRight: 5 }}
              />
              <h6 style={{ margin: 0, color: 'white' }}>App Store</h6>
            </a>
            <a
              href='https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000759168'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#bebef4',
                borderRadius: 30,
                padding: '8px 10px',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 15,
              }}
            >
              <img
                src={onestore}
                style={{ width: 'auto', height: '1em', marginRight: 0 }}
              />
              <h6 style={{ margin: 0, color: 'white' }}>One Store</h6>
            </a>
          </div>

          {/* pro web 소개 */}
          <div
            style={{
              display: 'flex',
              width: '80%',
              height: getWindowDimensions().width * 0.39,
              paddingLeft: '10%',
              paddingRight: '10%',
              marginBottom: '0vh',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center',
              backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/Home/Favi Pro.png')`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right center',
            }}
          >
            <h6
              style={{
                margin: 0,
                color: '#666',
                marginBottom: 5,
                fontFamily: 'NexaDemo',
              }}
            >
              프로 아티스트를 위한
            </h6>
            <h5 style={{ margin: 0, color: 'black' }}>레퍼런스 검색 및</h5>
            <h5 style={{ margin: 0, color: 'black' }}>비트 마켓 서비스</h5>
            <img
              src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/logo_pro.png`}
              style={{ width: 'auto', height: '1.5em', marginTop: 10 }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '1vh 7%',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: '3vh',
            }}
          >
            <a
              href='https://favi.kr'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#e1e1ff',
                borderRadius: 30,
                padding: '9px 15px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <h6 style={{ margin: 0, color: '#57477d' }}>서비스 바로가기</h6>
            </a>
          </div>
          {/* NEWS */}
          <div
            style={{
              display: 'flex',
              width: '90%',
              padding: '0 5%',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: '5vh',
            }}
          >
            <h3 style={{ color: '#57477d', fontFamily: 'NexaDemoBold' }}>
              News
            </h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              {news_list.map((item, i) => {
                let item_width = (getWindowDimensions().width * 0.9) / 2 - 10;
                return (
                  <a
                    href={item.url}
                    target='_blank'
                    style={{
                      display: 'flex',
                      textDecoration: 'none',
                      width: item_width,
                      height: 'auto',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: 5,
                    }}
                  >
                    <img
                      src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/news${
                        i + 1
                      }.png`}
                      style={{
                        width: '100%',
                        margin: '0%',
                        height: 'auto',
                        borderRadius: 5,
                      }}
                    />
                    <h6
                      style={{
                        margin: 0,
                        marginTop: 10,
                        color: 'black',
                        textAlign: 'left',
                        fontSize: 12,
                        fontWeight: 'normal',
                      }}
                    >
                      {item.title}
                    </h6>
                    <h6
                      style={{
                        margin: 0,
                        marginTop: 5,
                        fontWeight: 'normal',
                        color: '#888',
                      }}
                    >
                      {item.date}
                    </h6>
                  </a>
                );
              })}
            </div>
            <NavLink
              to='/News'
              state={{ fromDashboard: true }}
              style={{
                textDecoration: 'none',
                border: '1px solid #57477d',
                backgroundColor: 'white',
                borderRadius: 30,
                height: 33,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0px 13px',
                marginTop: '3vh',
                marginBottom: '5vh',
              }}
            >
              <h6 style={{ margin: 0, marginRight: 10, color: '#57477d' }}>
                뉴스 더보기
              </h6>
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/more_right.png`}
                style={{ width: 'auto', height: '0.6em' }}
              />
            </NavLink>
          </div>
        </div>
        <Footer history={history} base={'./'} type={0} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
      }}
    >
      <Header base={'./'} history={history} type={0} />
      {/* MAIN */}
      <div
        style={{
          marginTop: '8vh',
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: getWindowDimensions().width * 0.287,
            backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/Home/main.png')`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h1
            style={{
              margin: 0,
              color: 'white',
              textShadow: '1px 1px rgba(0,0,0,0.3)',
              fontSize: 33,
              fontFamily: 'NexaBold',
            }}
          >
            Make your Art Techful, Make your Tech Artistic
          </h1>
          <h4
            style={{
              margin: 0,
              marginTop: 20,
              color: 'white',
              textShadow: '1px 1px rgba(0,0,0,0.3)',
              fontFamily: 'NexaBold',
            }}
          >
            IT 기술로 현실화된 예술 플랫폼의 새로운 시작
          </h4>
        </div>
        {/* ABOUT ARCANE */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '5vh',
          }}
        >
          <h2 style={{ color: '#57477d', fontFamily: 'NexaBold' }}>
            About Arcane
          </h2>
          <h5 style={{ margin: 0, fontWeight: 'normal', color: '#444' }}>
            새로운 경험과 함께 더 쉬운 예술을 즐길 수 있게,
          </h5>
          <h5 style={{ margin: 0, fontWeight: 'normal', color: '#444' }}>
            더 편리해진 창작 활동, 대중에게 더 쉽게 다가가고 싶은 아티스트를
            위해!
          </h5>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: '10vh',
              paddingBottom: '15vh',
            }}
          >
            {about_list.map((item, i) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    height: '20vh',
                  }}
                >
                  <img
                    src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/${item.title}.png`}
                    style={{ height: '4.5em', width: 'auto' }}
                  />
                  <h3 style={{ fontFamily: 'NexaBold' }}>{item.title}</h3>
                  <h5
                    style={{
                      margin: 0,
                      fontWeight: 'normal',
                      color: '#666',
                      fontSize: 12,
                    }}
                  >
                    {item.contents}
                  </h5>
                  <h5
                    style={{
                      margin: 0,
                      fontWeight: 'normal',
                      color: '#666',
                      fontSize: 12,
                    }}
                  >
                    {item.contents2}
                  </h5>
                </div>
              );
            })}
          </div>
        </div>
        {/* FAVI 소개 */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            height: getWindowDimensions().width * 0.39,
            paddingLeft: '15%',
            paddingRight: '15%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/Home/Favi.png')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <h4
            style={{
              margin: 0,
              color: '#666',
              marginBottom: 10,
              fontFamily: 'NexaDemo',
            }}
          >
            Let's fall in Vibe
          </h4>
          <h2 style={{ margin: 0, color: 'black' }}>다중 키워드</h2>
          <h2 style={{ margin: 0, color: 'black' }}>음악 검색 어플리케이션</h2>
          <img
            src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/logo.png`}
            style={{ width: 'auto', height: '1.8em', marginTop: 15 }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '5vh 0px',
              alignItems: 'center',
            }}
          >
            <a
              href='https://play.google.com/store/apps/details?id=com.mureka'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#bebef4',
                borderRadius: 30,
                padding: '10px 15px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/android.png`}
                style={{ width: 'auto', height: '1.1em', marginRight: 10 }}
              />
              <h5 style={{ margin: 0, color: 'white' }}>Google Play</h5>
            </a>
            <a
              href='https://apps.apple.com/kr/app/favi-%ED%8C%8C%EB%B9%84-%EA%B5%AC-%EB%AE%A4%EB%A0%88%EC%B9%B4-mureka/id1559270799'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#bebef4',
                borderRadius: 30,
                padding: '10px 15px',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 15,
              }}
            >
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/ios.png`}
                style={{ width: 'auto', height: '1.1em', marginRight: 10 }}
              />
              <h5 style={{ margin: 0, color: 'white' }}>App Store</h5>
            </a>
            <a
              href='https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000759168'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#bebef4',
                borderRadius: 30,
                padding: '10px 15px',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 15,
              }}
            >
              <img
                src={onestore}
                style={{ width: 'auto', height: '1.1em', marginRight: 10 }}
              />
              <h5 style={{ margin: 0, color: 'white' }}>One Store</h5>
            </a>
          </div>
        </div>
        {/* pro web 소개 */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            height: getWindowDimensions().width * 0.39,
            paddingLeft: '15%',
            paddingRight: '15%',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/Home/Favi Pro.png')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <h4 style={{ margin: 0, color: '#888', marginBottom: 10 }}>
            프로 아티스트를 위한
          </h4>
          <h2 style={{ margin: 0, color: 'black' }}>
            레퍼런스 검색 및 비트 마켓 서비스
          </h2>
          <img
            src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/logo_pro.png`}
            style={{ width: 'auto', height: '2.2em', marginTop: 15 }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '5vh 0px',
              alignItems: 'center',
            }}
          >
            <a
              href='https://favi.kr'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#e1e1ff',
                borderRadius: 30,
                padding: '10px 20px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <h5 style={{ margin: 0, color: '#57477d' }}>서비스 바로가기</h5>
            </a>
          </div>
        </div>
        {/* NEWS */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <h2 style={{ color: '#57477d', fontFamily: 'NexaBold' }}>News</h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              marginTop: '2vh',
            }}
          >
            {news_list.map((item, i) => {
              let item_width = (getWindowDimensions().width * 0.7) / 4;
              return (
                <a
                  href={item.url}
                  target='_blank'
                  style={{
                    display: 'flex',
                    textDecoration: 'none',
                    width: item_width,
                    height: 'auto',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: 5,
                  }}
                >
                  <img
                    src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/news${i + 1}.png`}
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: 5,
                      overflow: 'hidden',
                    }}
                  />
                  <h5
                    style={{
                      margin: 0,
                      marginTop: 15,
                      color: 'black',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    {item.title}
                  </h5>
                  <h6
                    style={{
                      margin: 0,
                      marginTop: 10,
                      fontWeight: 'normal',
                      color: '#888',
                    }}
                  >
                    {item.date}
                  </h6>
                </a>
              );
            })}
          </div>
          <NavLink
            state={{
              fromDashboard: true,
            }}
            to={'/News'}
            style={{
              textDecoration: 'none',
              border: '1px solid #57477d',
              backgroundColor: 'white',
              borderRadius: 30,
              height: 35,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '0px 20px',
              marginTop: '3vh',
              marginBottom: '15vh',
            }}
          >
            <h5 style={{ margin: 0, marginRight: 15, color: '#57477d' }}>
              뉴스 더보기
            </h5>
            <img
              src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/more_right.png`}
              style={{ width: 'auto', height: '0.8em' }}
            />
          </NavLink>
        </div>

        {/* FOOTER */}
        <Footer history={history} base={'./'} />
      </div>
    </div>
  );
};

export default Home;
