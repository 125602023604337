export default function AlertModal(props) {
  const { isOpen, onClose } = props;

  return (
    <button
      type='button'
      onClick={onClose}
      className={`AlertModal${isOpen ? ' AlertModal--isOpen' : ''}`}
    >
      <div className='column AlertModal__box'>
        <img
          alt='Check'
          src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/Check__contact.webp'
        />
        <h1>THANK YOU!</h1>
        <span>The form was submitted successfully.</span>
        <button type='button' onClick={onClose}>
          OK
        </button>
      </div>
    </button>
  );
}
