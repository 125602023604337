import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useWindowSize from '../API/useWindowSize';
import Footer from './ITEM/Footer';
import Header from './ITEM/Header';
import onestore from '../img/onestore.png';

const useStyles = makeStyles((theme) => ({
  header: {
    height: '8vh',
    width: '90%',
    display: 'flex',
    position: 'fixed',
    top: '0',
    paddingLeft: '5%',
    paddingRight: '5%',
    zIndex: 5,
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
}));

const Download = ({ history }) => {
  const SERVER_IMG_URL =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/';
  const { width } = useWindowSize();

  const news_list = [
    {
      title: "대한민국 음악 큐레이션계의 '트랜드세터'",
      date: '2021. 04. 05',
      url: 'http://www.issuemaker.kr/news/articleView.html?idxno=33135',
    },
    {
      title: '[히든 챔피언] 천미르 아케인 주식회사 대표',
      date: '2021. 04. 05',
      url: 'https://m.post.naver.com/viewer/postView.nhn?volumeNo=31135428&memberNo=5899174',
    },
  ];

  const classes = useStyles();
  const [count, setCount] = useState(1);
  const controlsProps = {
    style: {
      left: '50%',
      top: 10,
      position: 'fixed',
      transform: 'translateX(-30%)',
    },
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  if (
    width < 500 ||
    getWindowDimensions().width < 500 ||
    getWindowDimensions().width <= getWindowDimensions().height
  ) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Header base={'./'} history={history} type={5} />

        <div
          style={{
            display: 'block',
            height: '88vh',
            width: '100%',
            flexDirection: 'column',
            overflow: 'scroll',
          }}
        >
          {/* mobile main */}

          <div
            style={{
              display: 'flex',
              width: '80%',
              height: '88vh',
              paddingLeft: '10%',
              paddingRight: '10%',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/Home/Favi.png')`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center',
            }}
          >
            <h3
              style={{
                margin: 0,
                color: '#666',
                fontWeight: 'normal',
                marginBottom: 10,
                fontFamily: 'NexaDemo',
              }}
            >
              Let's fall in Vibe
            </h3>
            <h2 style={{ margin: 0, color: 'black' }}>다중 키워드</h2>
            <h2 style={{ margin: 0, color: 'black' }}>
              음악 검색 어플리케이션
            </h2>
            <img
              src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/logo.png`}
              style={{ width: 'auto', height: '1.8em', marginTop: 25 }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '5vh 0px',
                alignItems: 'flex-start',
                marginTop: '2vh',
              }}
            >
              <a
                href='https://play.google.com/store/apps/details?id=com.mureka'
                target='_blank'
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  backgroundColor: '#9058fa',
                  borderRadius: 30,
                  padding: '15px 20px',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/android.png`}
                  style={{ width: 'auto', height: '1em', marginRight: 10 }}
                />
                <h5 style={{ margin: 0, color: 'white' }}>Google Play</h5>
              </a>
              <a
                href='https://apps.apple.com/kr/app/favi-%ED%8C%8C%EB%B9%84-%EA%B5%AC-%EB%AE%A4%EB%A0%88%EC%B9%B4-mureka/id1559270799'
                target='_blank'
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  backgroundColor: '#9058fa',
                  borderRadius: 30,
                  padding: '15px 20px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <img
                  src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/ios.png`}
                  style={{ width: 'auto', height: '1em', marginRight: 10 }}
                />
                <h5 style={{ margin: 0, color: 'white' }}>App Store</h5>
              </a>
              <a
                href='https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000759168'
                target='_blank'
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  backgroundColor: '#9058fa',
                  borderRadius: 30,
                  padding: '15px 20px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <img
                  src={onestore}
                  style={{ width: 'auto', height: '1em', marginRight: 10 }}
                />
                <h5 style={{ margin: 0, color: 'white' }}>One Store</h5>
              </a>
            </div>
          </div>
        </div>

        <Footer base={'./'} history={history} type={5} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
      }}
    >
      <Header base={'./'} history={history} type={5} />
      {/* MAIN */}
      <div
        style={{
          marginTop: '8vh',
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '70%',
            height: '92vh',
            paddingLeft: '15%',
            paddingRight: '15%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/Home/Favi.png')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <h2
            style={{
              margin: 0,
              color: '#666',
              fontWeight: 'normal',
              marginBottom: 10,
              fontFamily: 'NexaDemo',
            }}
          >
            Let's fall in Vibe
          </h2>
          <h1 style={{ margin: 0, color: 'black', fontSize: 40 }}>
            다중 키워드
          </h1>
          <h1 style={{ margin: 0, color: 'black', fontSize: 40 }}>
            음악 검색 어플리케이션
          </h1>
          <img
            src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/logo.png`}
            style={{ width: 'auto', height: '2.3em', marginTop: 25 }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '5vh 0px',
              alignItems: 'center',
              marginTop: '5vh',
            }}
          >
            <a
              href='https://play.google.com/store/apps/details?id=com.mureka'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#9058fa',
                borderRadius: 30,
                padding: '15px 20px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/android.png`}
                style={{ width: 'auto', height: '1em', marginRight: 10 }}
              />
              <h5 style={{ margin: 0, color: 'white' }}>Google Play</h5>
            </a>
            <a
              href='https://apps.apple.com/kr/app/favi-%ED%8C%8C%EB%B9%84-%EA%B5%AC-%EB%AE%A4%EB%A0%88%EC%B9%B4-mureka/id1559270799'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#9058fa',
                borderRadius: 30,
                padding: '15px 20px',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 20,
              }}
            >
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/Home/ios.png`}
                style={{ width: 'auto', height: '1em', marginRight: 10 }}
              />
              <h5 style={{ margin: 0, color: 'white' }}>App Store</h5>
            </a>
            <a
              href='https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000759168'
              target='_blank'
              style={{
                display: 'flex',
                textDecoration: 'none',
                backgroundColor: '#9058fa',
                borderRadius: 30,
                padding: '15px 20px',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 20,
              }}
            >
              <img
                src={onestore}
                style={{ width: 'auto', height: '1em', marginRight: 10 }}
              />
              <h5 style={{ margin: 0, color: 'white' }}>One Store</h5>
            </a>
          </div>
        </div>

        {/* FOOTER */}
        {
          // <Footer history={history} base={'./'} />
        }
      </div>
    </div>
  );
};

export default Download;
