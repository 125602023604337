import React, { useEffect } from 'react';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const [isTop, setIsTop] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [navList, setNavList] = useState([
    {
      title: 'HOME',
      path: '/',
      children: null,
    },
    {
      title: 'SERVICE',
      path: '/Service',
      children: [
        'Favi',
        //  'Chart'
      ],
    },
    {
      title: 'ABOUT US',
      path: '/AboutUs',
      children: null,
    },
    {
      title: 'NEWS',
      path: '/News',
      children: null,
    },
    {
      title: 'CONTACT US',
      path: '/ContactUs',
      children: null,
    },
  ]);

  useEffect(() => {
    // const doc = document.querySelector('body');
    let doc = document.getElementById('layout');
    function scrollFunction() {
      const value = doc.scrollTop; // 세로 스크롤 값을 value 함수에 저장한다.
      if (value < 200 && !isTop) {
        setIsTop(true);
      } else if (value >= 200 && isTop) {
        setIsTop(false);
      }
      setIsOpen(false);
    }
    try {
      doc.addEventListener('scroll', scrollFunction);
      return function cleanUp() {
        doc.removeEventListener('scroll', scrollFunction);
      };
    } catch (error) {}
  }, [isTop]);

  const handleNavigate = (e) => {
    const { value } = e.currentTarget;
    navigate(value);
  };
  const handleMenuClose = () => {
    setIsOpen(false);
  };
  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  return (
    <header
      className={`Header${isTop ? ' Header--isTop' : ''}${
        isOpen ? ' Header--isOpen' : ''
      }`}
    >
      <NavLink className='Header__logo' to={'/'}>
        <img
          alt='Arcane Inc.'
          src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/Logo__full.webp'
        />
      </NavLink>
      <div className='column Header__box'>
        {isTop || isOpen ? (
          <div className='column Header__list'>
            {navList.map((nl) => (
              <div key={`header_${nl.title}`} className='column Header__nav'>
                <button type='button' value={nl.path} onClick={handleNavigate}>
                  {nl.title}
                </button>
                {nl.children &&
                  nl.children.map((nc) => (
                    <button
                      key={`header_children_${nc}`}
                      type='button'
                      className='children'
                      value={`${nl.path}/${nc}`}
                      onClick={handleNavigate}
                    >
                      {nc}
                    </button>
                  ))}
              </div>
            ))}
            <button
              type='button'
              className='Header__close'
              onClick={handleMenuClose}
            >
              <img
                alt='Close'
                className='Header__icon'
                src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/Close__lightgray.webp'
              />
            </button>
          </div>
        ) : (
          <button
            type='button'
            className='Header__menu'
            onClick={handleMenuOpen}
          >
            <img
              alt='Menu'
              className='Header__icon'
              src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/Menu__gray.webp'
            />
          </button>
        )}
      </div>
    </header>
  );
}
