import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function Favi(props) {
  const [isPlay, setIsPlay] = useState(false);
  const [feature] = useState([
    {
      title: `FAVI PREMIUM PRODUCTION`,
      key: 'Premium',
      contents: `'Favi' produces premium quality loops and sound samples that can only be found on the platform.`,
    },
    {
      title: `AUTOMATED PLAYLIST`,
      key: 'Playlist',
      contents: `When you find your favorite music or sample. click the 'Like' button. Automatically sorted by keyword and listed in the playlist.`,
    },
    {
      title: `EASY INTERCONNECTION`,
      key: 'Interconnection',
      contents: `Users can connect with the music streaming service they use. Whatever service you use, you can connect more than 10 major music streaming services.`,
    },
  ]);
  const [keyword] = useState([
    {
      title: `KEYWORDS`,
      contents: `'Favi' has more than 250 keywords including genre, musical instrument, rhythm, vocal etc.\nCreators like artist, sound engineer, sound designer, music directors can find musical references and sampling sources more easily with combining keywords presented.`,
      image:
        'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/App__cover.webp',
    },
    {
      title: 'QURATION',
      contents: `Based on the music that each user searched and liked, 'Favi' presents data based quration system.\nComparing data of user and of music itself. We make a more precise recommendations.`,
      image:
        'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/App__cover.webp',
    },
    {
      title: 'MUSIC DATA',
      contents: `With our music anlysis algorithm and music professionals, 'Favi' analyze each music accurately based on musical theory and harmonics. Now you don't need to hear all music presented to tell which music you wanted.`,
      image:
        'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/App__cover.webp',
    },
  ]);
  const [currentkeword, setCurrentKeyword] = useState(0);

  const handlePlay = () => {
    const doc = document.getElementById('favi_video');
    if (isPlay) {
      doc.pause();
    } else {
      doc.play();
    }
    setIsPlay(!isPlay);
  };

  const scrollIndicator = (value) => {
    let scroll = document.getElementById('scroll_keyword');
    scroll.scrollTo(scroll.clientWidth * +value, 0);
  };
  const handleIndicator = (e) => {
    const { value } = e.currentTarget;
    setCurrentKeyword(+value);
    scrollIndicator(value);
  };

  const handlePrev = () => {
    setCurrentKeyword(currentkeword - 1);
    scrollIndicator(currentkeword - 1);
  };

  const handleNext = () => {
    setCurrentKeyword(currentkeword + 1);
    scrollIndicator(currentkeword + 1);
  };

  return (
    <section className='column Service__favi'>
      <article className='column ServiceFavi__introduce'>
        <img
          alt='Arcane Inc.'
          src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/Logo.webp'
        />
        <h1>{`YOU CAN ENJOY NEW EXPERIENCES AND EASIER ART.\nLET ME INTRODUCE FAVI`}</h1>
      </article>
      <article className='column ServiceFavi__diggin'>
        <div>
          <span>{`specialized in diggin, 'Favi' presents multi-keyword searching system for released music and sampling sources. Both listners and creators can shorten time looking for sounds that they are looking for.`}</span>
        </div>
        <h3 className='Main__repeat Main__repeat--h2'>
          DO THE DIGGIN&nbsp;&nbsp;&nbsp;&nbsp;DO THE
          DIGGIN&nbsp;&nbsp;&nbsp;&nbsp;DO THE DIGGIN&nbsp;&nbsp;&nbsp;&nbsp;DO
          THE DIGGIN
        </h3>
        <div>
          <span>{`'Favi' is equiped with more than 250 keywords\nfor searching music and labeling data based quration system.\nCreators can find sampling sources more easily with detailed keywords and labeling\ndate. You will experience dramatic shortening in time for your creation.`}</span>
        </div>
      </article>
      <h3 className='Main__repeat'>
        NEW WAVE IN POP CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP
        CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP
        CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP CULTURE
      </h3>
      <article className='column ServiceFavi__life'>
        <nav className='Service__nav'>
          <a>OUR RELEASE</a>
          <a>OUR RELEASE</a>
          <a>OUR RELEASE</a>
        </nav>
        <h1>{`MEET A NEW LEVEL\nOF YOUR MUSIC LIFE.`}</h1>
      </article>
      <article className='column ServiceFavi__keyword'>
        <div id='scroll_keyword' className='ServiceFavi__box'>
          {keyword.map((kw) => (
            <div
              key={`keyword__${kw.title}`}
              className='column KeywordCard'
              // style={{ backgroundImage: `url('${kw.image}')` }}
            >
              <img alt='cover' src={kw.image} />
              <h1>{kw.title}</h1>
              <span>{kw.contents}</span>
              <NavLink to={'https://fallinvibe.com'} replace>
                GO TO THE SERVICE
              </NavLink>
            </div>
          ))}
        </div>
        <button
          type='button'
          className='ServiceFavi__arrow ServiceFavi__arrow--prev'
          onClick={handlePrev}
          disabled={currentkeword === 0}
        >
          <img
            alt='arrow'
            src={`https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/ArrowRight__black.webp`}
          />
        </button>
        <button
          type='button'
          className='ServiceFavi__arrow ServiceFavi__arrow--next'
          onClick={handleNext}
          disabled={currentkeword === 2}
        >
          <img
            alt='arrow'
            src={`https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/ArrowRight__black.webp`}
          />
        </button>
        <div className='ServiceFavi__indicator'>
          {keyword.map((kw, idx) => (
            <button
              className={idx === currentkeword ? 'active' : ''}
              key={`keyword_indicator_${kw.title}`}
              onClick={handleIndicator}
              value={idx}
              type='button'
            />
          ))}
        </div>
      </article>

      {/* 구독 benefit */}
      <article className='ServiceFavi__benefit'>
        <h1>{`SUBSCRIPTION BENEFIT`}</h1>
        <img
          alt='SAMPLING'
          src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/SourceDeal__cover.webp'
        />
        <div className='column ServiceFavi__box'>
          <h3>{`SAMPLING\nSOURCE DEAL`}</h3>
          <span>{`Membership users can buy and download Favi's premium sound samples. Users who was certified can also upload and sell their own samples freely. You will meet sound quality samples only in 'Favi'`}</span>
          <h5>{`PREMIUM 'FAVI'\nSAMPLE PACK`}</h5>
        </div>
        <div className='column ServiceFavi__box'>
          <h3>{`MULTI-KEYWORD\nSEARCHING`}</h3>
          <span>{`Membership users can use unlimited multi-keyword searching for music and sound samples. Users who don't subscribe has 10-time limitation of using all keywords we present. Subscribe and enjoy ultimate convenience.`}</span>
          <h5>{`UNLIMITED CONVENIENT\nSEARCH ENGINE`}</h5>
        </div>
        <img
          alt='Multi-Keyword'
          src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/MultiKeyword__cover.webp'
        />
      </article>
      {/* FEATURE */}
      <article className='column ServiceFavi__feature'>
        <h1>{`FEATURES\nONLY IN ARCANE`}</h1>
        <div className='ServiceFavi__box'>
          <span>{`Features you will only find in 'Favi'. This is the reason why you should use 'Favi'.`}</span>
          <div className='ServiceFavi__list'>
            {feature.map((ft, idx) => (
              <div className='column FeatureCard'>
                <span className='FeatureCard__index'>{`0${idx + 1}`}</span>
                <h5 className='FeatureCard__title'>{ft.title}</h5>
                <img
                  alt='FEATURE'
                  className='FeatureCard__icon'
                  src={`https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/${ft.key}__feature.webp`}
                />
                <span className='FeatureCard__contents'>{ft.contents}</span>
              </div>
            ))}
          </div>
        </div>
      </article>
      {/* 광고 영상 */}
      <article className='column ServiceFavi__video'>
        <video id='favi_video' className='ServiceFavi__video' loop>
          <source
            type='video/mp4'
            src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_VIDEO/favi_ad.mp4'
          ></source>
        </video>
        <button type='button' className='play' onClick={handlePlay}>
          <img
            alt='play'
            src={`https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/${
              isPlay ? 'Pause' : 'Play'
            }__white.webp`}
          />
        </button>
      </article>
    </section>
  );
}
