import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../component/common';
import Footer from '../../component/common/Footer';
import AlertModal from '../../component/contactus/AlertModal';
import '../../scss/contact.scss';

export default function ContactUs() {
  const SERVER_IMG_URL =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/';
  const mapRef = useRef();
  const { kakao } = window;
  const [mapData, setMapData] = useState(null);
  const [isAlertShow, setIsAlertShow] = useState(false);

  const [form, setForm] = useState({
    name: '',
    email: '',
    title: '',
    tel: '',
    message: '',
    agree: false,
  });
  const [offices] = useState([
    {
      title: 'HEADQUARTER',
      address:
        '45, Yangcheong 4-gill, Ochang-eup, Cheongwon-gu, Cheongju-si, ChungCheongbuk-do, 28116, Rep. of KOREA',
      tel: '+82 1533-6017',
      lat: 36.727799547669534,
      lan: 127.43806744508854,
      path: 'http://kko.to/B1RIMcFf0',
    },
    {
      title: 'MAIN OFFICE',
      address: '30, Seongam-ro 15-gill, Mapo-gu, Seoul, 03930, Rep. of KOREA',
      tel: '+82 070-8015-2497',
      lat: 37.5774779,
      lan: 126.89604929999999,
      path: 'http://kko.to/ZVDpaZEjiN',
    },
    {
      title: 'R&D CENTER',
      address: '153, Chimsan-ro, Buk-gu, Daegu, 41559, Rep. of KOREA',
      tel: '+82 010-3504-5010',
      lat: 35.890182599999996,
      lan: 128.5900294,
      path: 'http://kko.to/r6ISBkfgfR',
    },
  ]);
  const [currentOffice, setCurrentOffice] = useState(0);
  const [currentLatLan, setCurrentLatLan] = useState({
    lat: 36.727799547669534,
    lan: 127.43806744508854,
  });

  useEffect(() => {
    // console.log('mobile?',isTabletOrMobile);
    async function setMap() {
      try {
        const { lat, lan } = currentLatLan;
        // var markerPosition  = new kakao.maps.LatLng(35.87475102221974, 128.59524604358208);
        var container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
        var options = {
          //지도를 생성할 때 필요한 기본 옵션
          // center: new kakao.maps.LatLng(35.87475102221974, 128.59524604358208), //지도의 중심좌표.
          center: new kakao.maps.LatLng(36.727799547669534, 127.43806744508854), //지도의 중심좌표.
          level: 4, //지도의 레벨(확대, 축소 정도)
          draggable: false,
        };
        var map = new kakao.maps.Map(container, options);
        setMapData(map);

        var imageSrc = `https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/Marker.webp`; // 마커이미지의 주소입니다
        // imageSize = new kakao.maps.Size(27, 41.98), // 마커이미지의 크기입니다
        // imageOption = { offset: new kakao.maps.Point(15, 30) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

        // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
        // var markerImage = new kakao.maps.MarkerImage(
        //     imageSrc,
        //     imageSize,
        //     imageOption
        //   ),
        //   // markerPosition  = new kakao.maps.LatLng(35.87475102221974, 128.59524604358208);
        //   markerPosition = new kakao.maps.LatLng(
        //     // 36.727799547669534,
        //     // 127.43806744508854
        //     lat,
        //     lan
        //   );
        // // 마커를 생성합니다
        // var marker = new kakao.maps.Marker({
        //   position: markerPosition,
        //   image: markerImage, // 마커이미지 설정
        // });

        // 마커가 지도 위에 표시되도록 설정합니다
        // marker.setMap(map);

        for (var i = 0; i < offices.length; i++) {
          // 마커 이미지의 이미지 크기 입니다
          var imageSize = new kakao.maps.Size(24, 35);

          // 마커 이미지를 생성합니다
          var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);

          // 마커를 생성합니다
          var marker = new kakao.maps.Marker({
            map: map, // 마커를 표시할 지도
            position: new kakao.maps.LatLng(offices[i].lat, offices[i].lan), // 마커를 표시할 위치
            title: offices[i].title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
            image: markerImage, // 마커 이미지
          });
          marker.setMap(map);

          var content =
            '<div class="CustomOverlay" >' +
            `  <a href="${offices[i].path}" target="_blank" >` +
            `    <span class="title" >${offices[i].title}</span>` +
            ' <img alt="arrow" src="https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/ArrowNext__black.webp" />' +
            '  </a>' +
            '</div>';

          // 커스텀 오버레이가 표시될 위치입니다
          // var position = new kakao.maps.LatLng(35.87475102221974, 128.59524604358208);
          var position = new kakao.maps.LatLng(
            +offices[i].lat + 0.0013,
            +offices[i].lan - 0.0
          );

          // 커스텀 오버레이를 생성합니다
          new kakao.maps.CustomOverlay({
            map: map,
            position: position,
            content: content,
            yAnchor: 2.35,
          });
        }
      } catch (error) {}
    }
    if (kakao) {
      setMap();
    }

    return function cleanUp() {
      // clearTimeout(timeout);
    };
  }, [kakao]);

  const handleFormChange = (e) => {
    const { name, value } = e.currentTarget;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleFormAgree = (e) => {
    setForm({
      ...form,
      agree: !form.agree,
    });
  };
  const handleFormSubmit = async (e) => {
    // 문의 등록 api 호출
    try {
      const url = `https://api2.arcaneofficial.com/board/help`;
      const data = {
        email: form.email,
        name: form.name,
        title: form.title,
        contents: form.message,
        phoneNumber: form.tel,
        type: 'arcane',
        emailYn: 'Y',
        phoneYn: 'Y',
        isPublic: 'N',
      };
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(data),
      };
      const res = await fetch(url, options);
      const data2 = res.json();
      if (res.status === 201) {
        setForm({
          name: '',
          email: '',
          title: '',
          message: '',
          tel: '',
        });
        setIsAlertShow(true);
      } else {
        // console.log('err',data2);
        throw Error(data2);
      }
    } catch (error) {}
  };

  const handleOffice = (e) => {
    const { value } = e.currentTarget;
    setCurrentOffice(+value);
    handleScroll(+value);
  };

  const handleScroll = (value) => {
    let doc = document.getElementById('office_list');
    // console.log('val', value, 'width', doc.clientWidth);
    try {
      console.log(value, doc.clientWidth);
      doc.scrollTo(value * doc.clientWidth, 0);

      let lat = +offices[+value].lat;
      let lan = +offices[+value].lan;

      mapData.setCenter(new kakao.maps.LatLng(lat, lan));

      // mapData.setCenter(new kakao.maps.LatLng(lat, lan));
      setCurrentLatLan({
        lat,
        lan,
      });
      // console.log(mapRef);

      // doc.style.transform = `translateX(${value * doc.clientWidth})`;
    } catch (error) {}
  };

  const handlePrev = () => {
    const count = currentOffice - 1;
    console.log('count', count);
    handleScroll(+count);
    setCurrentOffice(+count);
  };
  const handleNext = () => {
    const count = currentOffice + 1;
    console.log('count', count);
    handleScroll(+count);
    setCurrentOffice(count);
  };

  const handleAlertClose = (e) => {
    // const { value } = e.currentTarget;
    setIsAlertShow(false);
  };

  return (
    <main id='layout' className='column Main ContactUs'>
      <Header />
      <section
        className='column ContactUs__header'
        style={{
          backgroundImage:
            'url("https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/ContactUs__banner.webp")',
        }}
      >
        <img
          alt='arrow'
          src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/ArrowNext__white.webp'
        />
        <div className='column ContactUs__box'>
          <h1>
            GET <strong>IN</strong> TOUCH
          </h1>
          <hr />
          <h1>
            ARCANE <strong>.</strong>
          </h1>
        </div>
      </section>
      <section className='column ContactUs__contact'>
        <nav className='ContactUs__nav'>
          <a>OUR RELEASE</a>
          <a>OUR RELEASE</a>
          <a>OUR RELEASE</a>
        </nav>
        <h1>CONTACT US</h1>
        <div className='ContactUs__wrap'>
          <input
            className='ContactUs__input'
            name='name'
            type={'text'}
            placeholder='NAME *'
            value={form.name}
            onChange={handleFormChange}
          />
          <input
            className='ContactUs__input'
            name='email'
            type={'text'}
            placeholder='EMAIL *'
            value={form.email}
            onChange={handleFormChange}
          />
          <input
            className='ContactUs__input'
            name='title'
            type={'text'}
            placeholder='TITLE *'
            value={form.title}
            onChange={handleFormChange}
          />
          <input
            className='ContactUs__input'
            name='tel'
            type={'text'}
            placeholder='TEL'
            value={form.tel}
            onChange={handleFormChange}
          />
          <textarea
            className='ContactUs__textarea'
            name='message'
            placeholder='MESSAGE *'
            value={form.message}
            onChange={handleFormChange}
          />
        </div>
        <div className='column ContactUs__footer'>
          <button
            className='ContactUs__checkbox'
            type='button'
            onClick={handleFormAgree}
          >
            <img
              alt='checkbox'
              src={`${SERVER_IMG_URL}CheckCircleLarge__${
                form.agree ? 'purple' : 'empty'
              }.webp`}
            />
            By sending confirmations that you accept the terms and privacy
            policy
          </button>
          <button
            onClick={handleFormSubmit}
            type='button'
            disabled={
              !(
                form.agree &&
                form.name !== '' &&
                form.email !== '' &&
                form.title !== '' &&
                form.message !== ''
              )
            }
            className='ContactUs__submit'
          >
            SUBMIT
          </button>
        </div>
      </section>
      <section className='column ContactUs__location'>
        <h1>LOCATION</h1>
        <div id='office_list' className='ContactUs__list'>
          {offices.map((of) => (
            <div className='ContactOffice' key={`office__${of.title}`}>
              <div className='column ContactOffice__box'>
                <h5>{of.title}</h5>
                <h5>ADDRESS</h5>
                <span>{of.address}</span>
                <h5>TEL</h5>
                <span>{of.tel}</span>
              </div>
            </div>
          ))}
          <div className='ContactOffice__map'>
            <div id='map' ref={mapRef} />
          </div>
          {/* <button
            onClick={handlePrev}
            disabled={currentOffice === 0}
            className='ContactUs__arrow ContactUs__arrow--prev'
            type='button'
          >
            <img src={SERVER_IMG_URL + 'ArrowRight__white.webp'} />
          </button>
          <button
            onClick={handleNext}
            disabled={currentOffice === 2}
            className='ContactUs__arrow ContactUs__arrow--next'
            type='button'
          >
            <img src={SERVER_IMG_URL + 'ArrowRight__white.webp'} />
          </button> */}
        </div>

        <nav className='ContactUs__nav'>
          <button
            className={`ContactUs__button${
              currentOffice === 0 ? ' ContactUs__button--active' : ''
            }`}
            type='button'
            value={0}
            onClick={handleOffice}
          >
            HEADQUARTER
          </button>
          <button
            className={`ContactUs__button${
              currentOffice === 1 ? ' ContactUs__button--active' : ''
            }`}
            type='button'
            value={1}
            onClick={handleOffice}
          >
            MAIN OFFICE
          </button>
          <button
            className={`ContactUs__button${
              currentOffice === 2 ? ' ContactUs__button--active' : ''
            }`}
            type='button'
            value={2}
            onClick={handleOffice}
          >
            R&amp;D CENTER
          </button>
        </nav>
      </section>
      <h3 className='Main__repeat'>
        NEW WAVE IN POP CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP
        CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP
        CULTURE&nbsp;&nbsp;&nbsp;&nbsp;NEW WAVE IN POP CULTURE
      </h3>
      <section className='column ContactUs__email'>
        <h1>@ARCANEOFFICIAL.COM</h1>
        <span>{`If you prefer, you can contact us directly\nthrough our phone number or e-mail`}</span>
      </section>
      <Footer />
      <AlertModal isOpen={isAlertShow} onClose={handleAlertClose} />
    </main>
  );
}
