import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { CrewProfile, PartnerCard } from '../../component/about';
import { Header, Footer } from '../../component/common';
import '../../scss/about.scss';
export default function AboutUs(props) {
  const [category] = useState([
    {
      key: 'Pop',
      title: 'Value of Pop Culture',
      contents:
        'We believe in the power of art and pop culture. We explore for the\nnew opportunities in pop industry.',
    },
    {
      key: 'Tech',
      title: 'Technical Advance',
      contents:
        'We develope technology with creative ideas. We are making new\necosystem in pop industry with technical advance.',
    },
    {
      key: 'Artist',
      title: 'A New Way of Creation',
      contents:
        'With our services and platform. creators and artists can focus on\ntheir work. Make your creation more efficiently.',
    },
    {
      key: 'Data',
      title: 'DATABASE & A.I.',
      contents:
        'We are making our own database of art & pop industry. Our database\nis being used to build. A.I. that benefits both creators and consumers.',
    },
  ]);
  const [currentCategory, setCurrentCategory] = useState('Pop');
  const [newHistory, setNewHistory] = useState(null);
  const [history] = useState([
    {
      date: '2020-09',
      title: 'Selected as "2020 Pre-Startup Package - Untact Service"',
    },
    {
      date: '2020-11',
      title: 'Arcane Inc. Established',
    },
    {
      date: '2020-12',
      title: 'Selected as "2020 Contents Service Invigoration Program"',
    },
    {
      date: '2021-3',
      title: 'Seed Investment from "Rowe Partners"',
    },
    {
      date: '2021-5',
      title: 'Best Company in "2020 Pre-Startup Package - Untact Service"',
    },
    {
      date: '2021-6',
      title: 'Selected as "2021 KOEF U300"',
    },
    {
      date: '2021-8',
      title: 'Top 200 in "K-Startup Challenge 2021"',
    },
    {
      date: '2022-2',
      title: 'Seed Investment from "CNU Technology Holdings"',
    },
    {
      date: '2022-3',
      title: 'Selected as "12th SBC Start-Up Arcademy"',
    },
    {
      date: '2022-4',
      title: 'Certified as "K-Venture Enterprise"',
    },
    {
      date: '2022-6',
      title: 'Seed Investment from "Korea Investment Accelerator"',
    },
    {
      date: '2022-7',
      title: 'Selected as "3rd Daejeon K-Camp Accelerating Program"',
    },
    {
      date: '2022-11',
      title: 'Best Company in "12th SBC Start-Up Arcademy"',
    },
  ]);
  const [month] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]);

  const [partner] = useState([
    {
      enterpriseEn: 'Chili Music Korea',
      enterpriseKo: '칠리뮤직코리아',
      url: 'https://www.chilimusic.co.kr/',
      description: '',
      image: 'chilimusic',
    },
    {
      enterpriseEn: 'Stela Music',
      enterpriseKo: '스텔라뮤직 주식회사',
      url: 'https://www.stelamusic.com/',
      description: '',
      image: 'Stela__logo',
    },
    {
      enterpriseEn: 'Rowe Partners',
      enterpriseKo: '로우 파트너스',
      url: 'https://www.rowe.kr/',
      description:
        'Specialized in public technology-based planning-type start-Accelerator',
      image: 'rowe',
    },
    {
      enterpriseEn: 'J&M Entertainment',
      enterpriseKo: 'J&M엔터테인먼트',
      url: 'https://www.jnment.com/',
      description: 'A comprehensive entertainment company',
      image: 'j&m',
    },
    {
      enterpriseEn: 'JCS Entertainment',
      enterpriseKo: 'JCS 엔터테인먼트',
      url: 'https://www.jcskorea.com/',
      description: 'A comprehensive entertainment company',
      image: 'jcs__white',
    },
    {
      enterpriseEn: 'Musia Publishing',
      enterpriseKo: '음원 유통사 뮤시아',
      url: 'https://themusia.modoo.at/',
      description: '',
      image: 'musia',
    },
    {
      enterpriseEn: 'Handrix',
      enterpriseKo: '핸드릭스',
      url: 'https://handrix.co.kr/',
      description: 'Music production, distribution, marketing platform',
      image: 'handrix',
    },
    {
      enterpriseEn: 'Velvet Studio',
      enterpriseKo: '벨벳 스튜디오',
      url: 'https://www.velvetsound.co.kr/',
      description: '',
      image: 'velvetstudio__white',
    },
    {
      enterpriseEn: 'Korea Investment',
      enterpriseKo: '한국투자 액셀러레이터',
      url: 'https://koreainvestment.ac/',
      description: '',
      image: 'koreainvestment__white',
    },
    {
      enterpriseEn: 'CNU Technology Holdings',
      enterpriseKo: '충남대학교기술지주(주)',
      url: 'https://connect.cnu.ac.kr/holdings',
      description: '',
      image: 'cnu',
    },
  ]);
  const [currentIdx, setCurrentIdx] = useState(0);

  useEffect(() => {
    if (history) {
      const groupedData = history.reduce((acc, item) => {
        const date = new Date(item.date).getFullYear(); // 날짜 문자열로 변환합니다.
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      }, {});
      setNewHistory(groupedData);
    }
  }, [history]);

  // useEffect(() => {
  //   let scroll = document.getElementById('partner_scroll');
  //   scroll.scrollTo(`${(currentIdx * 65) / 3}vw`, 0);
  // }, [currentIdx]);

  const handleCategory = (e) => {
    const { value } = e.currentTarget;
    setCurrentCategory(value);
  };

  const handlePrev = () => {
    let val = currentIdx - 1;
    scrollTo(val);
    setCurrentIdx(val);
  };

  const handleNext = () => {
    let val = currentIdx + 1;
    setCurrentIdx(val);
    scrollTo(val);
  };

  const scrollTo = (value) => {
    let doc = document.getElementById('partner_scroll');
    // console.log('client', doc.clientWidth);
    doc.scroll((+value * window.innerWidth * 0.65) / 3, 0);
    // doc.style.left = -value * doc.clientWidth;
  };

  return (
    <main className={'column Main AboutUs'}>
      <Header />
      <section
        className={'AboutUs__welcome'}
        style={{
          backgroundImage:
            "url('https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/AboutUs__banner.webp')",
        }}
      >
        <div className='column AboutUs__box'>
          <h1>Welcome to</h1>
          <div>
            <h1>Arcane</h1>
            <h3>Tech+Art</h3>
          </div>
          <span>
            {`Arcane Inc. pursues a `}
            <strong>new beginning of the art</strong>
            {` platform that has\nbecome real with IT technology.\nWe always thrive to make a new wave through collaboration of technical advance and emerging pop culture.\n\nExperience the essence of art & culture\nwith services presented by Arcane Inc.`}
          </span>
        </div>
      </section>
      <section className='AboutUs__category'>
        {category.map((cg, idx) => (
          <button
            type='button'
            key={`AboutCategory__${cg.key}`}
            onClick={handleCategory}
            value={cg.key}
            className={`column AboutUsCategory${
              cg.key === currentCategory ? ' AboutUsCategory--select' : ''
            }`}
          >
            <img
              className='AboutUsCategory__image'
              src={`https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/${cg.key}__cover.webp`}
              alt='thumbnail'
            />
            <span className='AboutUsCategory__index'>0{idx + 1}</span>
            <div className='column AboutUsCategory__box'>
              <span className='AboutUsCategory__title'>{cg.title}</span>
              <span className='AboutUsCategory__contents'>{cg.contents}</span>
              <span className='AboutUsCategory__key'>
                {cg.key.toUpperCase()}
              </span>
            </div>
          </button>
        ))}
      </section>
      <section className='AboutUs__culture'>
        <div className='column'>
          <h3>{`A new wave of pop culture come and experience`}</h3>
          <h3>{`a whole new level of cultural life`}</h3>
        </div>
        <div className='column'>
          <span>
            {`Arcane Inc. aim to develope new technologies that can help creators and consumers, collect massive data in pop & art industry, and nourish overall pop culture industry.\nYou can meet`}
            <strong>creator-oriented services</strong>
            {`and platforms that can secure cultural diversity in the field.`}
          </span>
          <span>{`We are eager to help creators and artists to focus on their creation.\nAdditionally, we thrive to make pop market more convenient for consumers to meet their taste of art works.\nYou will find Arcane Inc. making wholesome pop industry.`}</span>
        </div>
      </section>
      <section className='column AboutUs__evolution'>
        <div className='AboutUs__header'>
          <h3>{`The Evolution of\nOur Company`}</h3>
          <span>{`Steady and vital progress Arcane Inc. is making.\nWe are the future of pop industry.`}</span>
        </div>
        <div className='AboutUs__history'>
          {newHistory &&
            Object.keys(newHistory).map((date, index) => (
              <div key={`history__${date}`} className='column AboutUsHistory'>
                <h5 className='AboutUsHistory__date'>{date}</h5>{' '}
                {/* 날짜를 표시하는 컴포넌트 */}
                {newHistory[date].map((item, itemIndex) => (
                  <div
                    key={`history__${item.date}`}
                    className='column AboutUsHistory__box'
                  >
                    <span className='AboutUsHistory__month'>
                      {month[new Date(item.date).getMonth()]}
                    </span>
                    <span className='AboutUsHistory__contents'>
                      {item.title}
                    </span>{' '}
                    {/* 제목을 표시하는 컴포넌트 */}
                    {/* 그 외의 내용 표시 */}
                  </div>
                ))}
              </div>
            ))}
        </div>
        <h1>History</h1>
      </section>
      <section className='column AboutUs__crew'>
        <h3>Arcane Crews</h3>
        {/* ceo */}

        <CrewProfile
          isCeo
          name='Meer Chun'
          position='CEO'
          description={`Current pop music columnist & music contents creator.\nWith the insights and networks throughout art & pop industry, leading Arcane Inc.`}
          profileUrl='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/crew__cmr.webp'
        />
        <div className='column CrewBox'>
          <span className='CrewBox__team'>Designers</span>
          <div className='CrewBox__list'>
            <CrewProfile
              name='Jinsol Hong'
              position='Designer'
              profileUrl='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/crew__hjs.webp'
            />
          </div>
        </div>
        <div className='column CrewBox'>
          <span className='CrewBox__team'>Developers</span>
          <div className='CrewBox__list'>
            <CrewProfile
              name='Hoyoung Chun'
              position='Chief Developer'
              profileUrl='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/crew__chy.webp'
            />
            <CrewProfile
              name='Jina Chun'
              position='Front-end Developer'
              profileUrl='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/crew__cja.webp'
            />
            <CrewProfile
              name='Jaesung Ma'
              position='Back-end Developer'
              profileUrl='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/crew__mjs.webp'
            />
          </div>
        </div>
        <div className='column CrewBox'>
          <span className='CrewBox__team'>Cooperation</span>
          <div className='CrewBox__list'>
            <CrewProfile
              name='Seungkyu No'
              position='Senior Manager'
              profileUrl='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/crew__nsk.webp'
            />
            <CrewProfile
              name='Sunmin Jang'
              position='Manager'
              profileUrl='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/crew__jsm.webp'
            />
            <CrewProfile
              name='Dongwook Lee'
              position='Manager'
              profileUrl='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/crew__ldw.webp'
            />
          </div>
        </div>
      </section>
      <section className='column AboutUs__team'>
        <h5 className=''>
          <strong>Arcane team</strong>
          {` is a gather of people who loves art & pop culture. We all devote ourselves to secure `}
          <strong>cultural diversity,</strong>
          {` explore `}
          <strong>the essence of art,</strong>
          {` and make people to truly enjoy art & pop culture.\nWith our team's sicere mind and endeavor, we will make a new wave in this industry.`}
        </h5>
      </section>
      <section className='column AboutUs__partner'>
        <h3>Arcane Partners</h3>
        <div id='partner_scroll' className='AboutUs__box'>
          {partner.map((pt) => (
            <PartnerCard
              key={`partner__${pt.enterpriseEn}`}
              enterprise={pt.enterpriseEn}
              description={pt.description}
              image={pt.image}
              path={pt.url}
            />
          ))}
        </div>
        <button
          className='AboutUs__arrow AboutUs__arrow--prev'
          type='button'
          onClick={handlePrev}
          disabled={currentIdx === 0}
        >
          <img
            alt='arrow'
            src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/ArrowRight__white.webp'
          />
        </button>
        <button
          className='AboutUs__arrow AboutUs__arrow--next'
          type='button'
          onClick={handleNext}
          disabled={currentIdx === partner.length - 3}
        >
          <img
            alt='arrow'
            src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/webp/ArrowRight__white.webp'
          />
        </button>
      </section>
      <Footer />
    </main>
  );
}
