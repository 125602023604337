import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useWindowSize from '../API/useWindowSize';
import Footer from './ITEM/Footer';
import Header from './ITEM/Header';

const useStyles = makeStyles((theme) => ({
  header: {
    height: '8vh',
    width: '90%',
    display: 'flex',
    position: 'fixed',
    top: '0',
    paddingLeft: '5%',
    paddingRight: '5%',
    zIndex: 5,
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
}));

const News = ({ history }) => {
  const SERVER_IMG_URL =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/';
  const { width } = useWindowSize();

  const news_list = [
    {
      title: "대한민국 음악 큐레이션계의 '트랜드세터'",
      date: '2021. 04. 05',
      url: 'http://www.issuemaker.kr/news/articleView.html?idxno=33135',
    },
    {
      title: '[히든 챔피언] 천미르 아케인 주식회사 대표',
      date: '2021. 04. 05',
      url: 'https://m.post.naver.com/viewer/postView.nhn?volumeNo=31135428&memberNo=5899174',
    },
  ];

  const classes = useStyles();
  const [count, setCount] = useState(1);
  const controlsProps = {
    style: {
      left: '50%',
      top: 10,
      position: 'fixed',
      transform: 'translateX(-30%)',
    },
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  if (
    width < 500 ||
    getWindowDimensions().width < 500 ||
    getWindowDimensions().width <= getWindowDimensions().height
  ) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Header base={'./'} history={history} type={2} />

        <div
          style={{
            display: 'block',
            height: '88vh',
            width: '100%',
            flexDirection: 'column',
            overflow: 'scroll',
          }}
        >
          {/* mobile main */}
          <div
            style={{
              display: 'flex',
              width: '90%',
              height: getWindowDimensions().width * 0.4,
              padding: '0px 5%',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              borderBottom: '2px solid #8777ad',
            }}
          >
            <h2 style={{ margin: 0, color: '#57477d', fontFamily: 'NexaBold' }}>
              News
            </h2>
            <h5
              style={{
                color: '#444',
                fontWeight: 'normal',
                margin: 0,
                marginTop: 20,
              }}
            >
              아케인의 다양한 소식들을 알려드립니다.
            </h5>
          </div>
          {/* News */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: '3vh',
              paddingBottom: '3vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                paddingTop: '2vh',
                paddingBottom: '7vh',
              }}
            >
              {news_list.map((item, i) => {
                const item_width = getWindowDimensions().width;
                return (
                  <a
                    href={item.url}
                    target='_blank'
                    style={{
                      display: 'flex',
                      textDecoration: 'none',
                      width: item_width,
                      padding: '5%',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h4 style={{ margin: 0, color: 'black' }}>{item.title}</h4>
                    <h6
                      style={{
                        margin: 0,
                        fontWeight: 'normal',
                        color: '#888',
                        marginTop: 5,
                        marginBottom: 20,
                      }}
                    >
                      {item.date}
                    </h6>
                    <img
                      src={`${SERVER_IMG_URL}ARCANE_WEB/v3/News/news${
                        i + 1
                      }.png`}
                      style={{ width: '100%', height: 'auto', borderRadius: 5 }}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>

        <Footer base={'./'} history={history} type={2} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
      }}
    >
      <Header base={'./'} history={history} type={2} />
      {/* MAIN */}
      <div
        style={{
          marginTop: '8vh',
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '70%',
            height: getWindowDimensions().width * 0.15,
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            borderBottom: '2px solid #8777ad',
          }}
        >
          <h2 style={{ margin: 0, color: '#57477d', fontFamily: 'NexaBold' }}>
            News
          </h2>
          <h5
            style={{
              color: '#666',
              fontWeight: 'bold',
              margin: 0,
              marginTop: 20,
              fontFamily: 'NexaBold',
            }}
          >
            아케인의 다양한 소식들을 알려드립니다.
          </h5>
        </div>

        {/* News */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            minHeight: '80vh',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '3vh',
            paddingBottom: '3vh',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
              paddingTop: '5vh',
              paddingBottom: '7vh',
            }}
          >
            {news_list.map((item, i) => {
              const item_width = (getWindowDimensions().width * 0.7) / 3 - 20;
              return (
                <a
                  href={item.url}
                  target='_blank'
                  style={{
                    display: 'flex',
                    textDecoration: 'none',
                    width: item_width,
                    margin: 5,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      color: 'black',
                      fontSize: 15,
                      textAlign: 'left',
                    }}
                  >
                    {item.title}
                  </h3>
                  <h5
                    style={{
                      margin: 0,
                      fontWeight: 'normal',
                      color: '#777',
                      marginTop: 5,
                      marginBottom: 25,
                      fontSize: 12,
                    }}
                  >
                    {item.date}
                  </h5>
                  <img
                    src={`${SERVER_IMG_URL}ARCANE_WEB/v3/News/news${i + 1}.png`}
                    style={{
                      width: '95%',
                      height: 'auto',
                      borderRadius: 5,
                      overflow: 'hidden',
                    }}
                  />
                </a>
              );
            })}
          </div>
        </div>

        {/* FOOTER */}
        <Footer history={history} base={'./'} />
      </div>
    </div>
  );
};

export default News;
