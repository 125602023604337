import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useWindowSize from '../API/useWindowSize';
import { useMediaQuery } from 'react-responsive';

import Footer from './ITEM/Footer';
import Header from './ITEM/Header';

import {
  Button,
  NativeSelect,
  FormControl,
  InputBase,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    height: '8vh',
    width: '90%',
    display: 'flex',
    position: 'fixed',
    top: '0',
    paddingLeft: '5%',
    paddingRight: '5%',
    zIndex: 5,
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
  customoverlay: {
    backgroundColor: 'white',
  },
}));

const Contact = ({ history }) => {
  const SERVER_IMG_URL =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/';
  const SERVER_API_URL = 'https://api2.arcaneofficial.com/';
  const { width } = useWindowSize();
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const [typeList, setTypeList] = useState([
    {
      value: '1',
      label: '협업 및 사업 제안',
    },
    {
      value: '2',
      label: '아티스트 음원 및 라이센스 문의',
    },
    {
      value: '3',
      label: '서비스 사용 문의',
    },
    {
      value: '4',
      label: '기타',
    },
  ]);

  const classes = useStyles();
  const [count, setCount] = useState(1);
  const [type, setType] = useState('0');
  const [help, setHelp] = useState({
    name: '',
    email: '',
    title: '',
    contents: '',
    type: '0',
  });
  const { kakao } = window;
  useEffect(() => {
    // console.log('mobile?',isTabletOrMobile);
    let isMounted = true;
    if (isMounted) {
      setTimeout(async () => {
        var container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
        var options = {
          //지도를 생성할 때 필요한 기본 옵션
          // center: new kakao.maps.LatLng(35.87475102221974, 128.59524604358208), //지도의 중심좌표.
          center: new kakao.maps.LatLng(36.727799547669534, 127.43806744508854), //지도의 중심좌표.
          level: 3, //지도의 레벨(확대, 축소 정도)
          draggable: false,
        };

        var map = new kakao.maps.Map(container, options);
        // var markerPosition  = new kakao.maps.LatLng(35.87475102221974, 128.59524604358208);

        var imageSrc = `${SERVER_IMG_URL}ARCANE_WEB/v3/marker.png`, // 마커이미지의 주소입니다
          imageSize = new kakao.maps.Size(28, 42.98), // 마커이미지의 크기입니다
          imageOption = { offset: new kakao.maps.Point(15, 30) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

        // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
        var markerImage = new kakao.maps.MarkerImage(
            imageSrc,
            imageSize,
            imageOption
          ),
          // markerPosition  = new kakao.maps.LatLng(35.87475102221974, 128.59524604358208);
          markerPosition = new kakao.maps.LatLng(
            36.727799547669534,
            127.43806744508854
          );

        // 마커를 생성합니다
        var marker = new kakao.maps.Marker({
          position: markerPosition,
          image: markerImage, // 마커이미지 설정
        });
        // 마커가 지도 위에 표시되도록 설정합니다
        await marker.setMap(map);

        var content =
          '<div class="customoverlay" style="position: relative; bottom: 0px;border-radius: 5px; border: 1px solid #ddd; border-bottom: 1px solid #ddd; float: left;">' +
          '  <a href="http://kko.to/B1RIMcFf0" target="_blank" style="display:block;text-decoration:none;color:#000;text-align:center;border-radius:5px;font-size:14px;font-weight:bold;overflow:hidden;background: #57477d;background: #57477d url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png) no-repeat right 10px center;">' +
          '    <span class="title" style="display: block; text-align: center; background: #57477d; margin-right: 35px; padding: 8px 13px; font-size: 13px; font-weight: bold; color:white;">(주) 아케인</span>' +
          '  </a>' +
          '</div>';

        // 커스텀 오버레이가 표시될 위치입니다

        // var position = new kakao.maps.LatLng(35.87475102221974, 128.59524604358208);
        var position = new kakao.maps.LatLng(
          36.727799547669534,
          127.43806744508854
        );

        // 커스텀 오버레이를 생성합니다
        var customOverlay = new kakao.maps.CustomOverlay({
          map: map,
          position: position,
          content: content,
          yAnchor: 2.35,
        });
      }, 1500);
    }
    return function cleanUp() {
      isMounted = false;
    };
  }, [
    kakao.maps.CustomOverlay,
    kakao.maps.LatLng,
    kakao.maps.Map,
    kakao.maps.Marker,
    kakao.maps.MarkerImage,
    kakao.maps.Point,
    kakao.maps.Size,
  ]);

  const handleChange = (event) => {
    setHelp({
      name: help.name,
      email: help.email,
      title: help.title,
      contents: help.contents,
      type: event.target.value,
    });
  };
  const nameChange = (event) => {
    setHelp({
      name: event.target.value,
      email: help.email,
      title: help.title,
      contents: help.contents,
      type: help.type,
    });
  };
  const emailChange = (event) => {
    setHelp({
      name: help.name,
      email: event.target.value,
      title: help.title,
      contents: help.contents,
      type: help.type,
    });
  };
  const titleChange = (event) => {
    setHelp({
      name: help.name,
      email: help.email,
      title: event.target.value,
      contents: help.contents,
      type: help.type,
    });
  };
  const contentsChange = (event) => {
    setHelp({
      name: help.name,
      email: help.email,
      title: help.title,
      contents: event.target.value,
      type: help.type,
    });
  };

  const uploadHandler = async () => {
    if (help.type == '0') {
      alert('문의 종류를 선택해주세요.');
    } else {
      if (
        help.email.trim() !== '' &&
        help.name.trim() !== '' &&
        help.title.trim() !== '' &&
        help.contents.trim() !== ''
      ) {
        const url = `${SERVER_API_URL}help`;
        const data = {
          email: help.email,
          name: help.name,
          title: help.title,
          contents: help.contents,
          type: help.type,
        };
        const options = {
          method: 'POST',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify(data),
        };
        const res = await fetch(url, options);
        const data2 = await res.json();

        if (res.status === 201) {
          setHelp({
            name: '',
            email: '',
            title: '',
            contents: '',
            type: '0',
          });
          alert('문의가 접수되었습니다.');
        } else {
          // console.log('err',data2);
          throw Error(data2);
        }
      } else {
        alert('비어있는 항목이 있습니다. 한번더 확인해주세요.');
      }
    }
  };

  const controlsProps = {
    style: {
      left: '50%',
      top: 10,
      position: 'fixed',
      transform: 'translateX(-30%)',
    },
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 5,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ddd',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#57477d',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  if (
    width < 500 ||
    getWindowDimensions().width < 500 ||
    getWindowDimensions().width <= getWindowDimensions().height
  ) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Header base={'./'} history={history} type={3} />

        <div
          style={{
            display: 'block',
            height: '88vh',
            width: '100%',
            flexDirection: 'column',
            overflow: 'scroll',
          }}
        >
          {/* mobile main */}
          <div
            style={{
              display: 'flex',
              width: '90%',
              height: getWindowDimensions().width * 0.4,
              padding: '0px 5%',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              borderBottom: '2px solid #8777ad',
            }}
          >
            <h2 style={{ margin: 0, color: '#57477d', fontFamily: 'NexaBold' }}>
              Contact us
            </h2>
            <h5
              style={{
                color: '#444',
                fontWeight: 'normal',
                margin: 0,
                marginTop: 20,
                marginBottom: 0,
              }}
            >
              궁금하신 사항이 있으시면 문의 주세요.
            </h5>
            <h5
              style={{
                color: '#444',
                fontWeight: 'normal',
                margin: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              자세하게 안내해드리겠습니다.
            </h5>
          </div>
          {/* INPUT FORM */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              minHeight: '50vh',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: '3vh',
              paddingBottom: '3vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '90%',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                paddingTop: '2vh',
                paddingBottom: '2vh',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  minWidth: 330,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <h5 style={{ margin: 0, fontWeight: 'normal' }}>
                  이름(기업 담당자)
                </h5>
                <InputBase
                  id='standard-textarea'
                  // label="이름(기업 담당자)"
                  value={help.name}
                  onChange={nameChange}
                  placeholder=''
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: 5,
                    width: '100%',
                    textDecoration: 'none',
                    marginTop: 10,
                    marginBottom: 20,
                    padding: '5px 10px',
                  }}
                />
                <h5 style={{ margin: 0, fontWeight: 'normal' }}>제목</h5>
                <InputBase
                  id='standard-textarea'
                  // label="이름(기업 담당자)"
                  value={help.title}
                  onChange={titleChange}
                  placeholder=''
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: 5,
                    width: '100%',
                    textDecoration: 'none',
                    marginTop: 10,
                    marginBottom: 20,
                    padding: '5px 10px',
                  }}
                />
                <h5 style={{ margin: 0, fontWeight: 'normal' }}>이메일</h5>
                <InputBase
                  id='standard-textarea'
                  // label="이름(기업 담당자)"
                  value={help.email}
                  onChange={emailChange}
                  placeholder=''
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: 5,
                    width: '100%',
                    textDecoration: 'none',
                    marginTop: 10,
                    marginBottom: 20,
                    padding: '5px 10px',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  minWidth: 330,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <h5 style={{ margin: 0, fontWeight: 'normal' }}>문의 종류</h5>
                <FormControl
                  className={classes.formControl}
                  style={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginTop: 10,
                    marginBottom: 20,
                  }}
                >
                  <NativeSelect
                    id='demo-customized-select-native'
                    value={help.type}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                    style={{ width: '100%' }}
                  >
                    <option aria-label='None' value='0' disabled>
                      문의종류를 선택해주세요.
                    </option>

                    {typeList.map((option, i) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </NativeSelect>
                </FormControl>

                <h5 style={{ margin: 0, fontWeight: 'normal' }}>문의 내용</h5>
                <InputBase
                  id='standard-textarea'
                  // label="이름(기업 담당자)"
                  multiline
                  value={help.contents}
                  onChange={contentsChange}
                  rows={9}
                  rowsMax={9}
                  placeholder=''
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: 5,
                    width: '100%',
                    textDecoration: 'none',
                    marginTop: 10,
                    marginBottom: 10,
                    padding: '5px 10px',
                  }}
                />
              </div>
            </div>
            <div
              style={{
                zIndex: 10,
                marginBottom: '5vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Button
                onClick={() => {
                  uploadHandler();
                }}
                style={{
                  border: '1px solid #57477d',
                  borderRadius: 5,
                  width: '90%',
                  color: '#57477d',
                  fontWeight: 'bold',
                  fontSize: 13,
                  backgroundColor: 'white',
                }}
              >
                문의 하기
              </Button>
            </div>
          </div>
          {/* Location FORM */}
          <div
            style={{
              display: 'flex',
              width: '90%',
              padding: '0px 5%',
              marginTop: '5vh',
              height: getWindowDimensions().width * 0.12,
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              borderBottom: '2px solid #8777ad',
            }}
          >
            <h2 style={{ margin: 0, color: '#57477d', fontFamily: 'NexaBold' }}>
              Location
            </h2>
            <h5
              style={{
                color: '#444',
                fontWeight: 'normal',
                margin: 0,
                marginTop: 15,
                marginBottom: 20,
              }}
            >
              찾아오시는 길
            </h5>
          </div>
          <div
            style={{
              display: 'flex',
              width: '90%',
              padding: '0px 5%',
              minHeight: '40vh',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              flexDirection: 'row',
              paddingTop: '5vh',
              paddingBottom: '15vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                minWidth: '50%',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <h5 style={{ margin: 0, fontWeight: 'bold', marginTop: '1vh' }}>
                주소
              </h5>
              {
                // <h5 style={{margin:0,fontWeight:'normal',color:'#444',marginTop:8,marginBottom:20,textAlign:'left'}}>대구광역시 중구 태평로 160, 11층 1105호 (북성로1가, 대구스테이션센터)</h5>
              }
              <h5
                style={{
                  margin: 0,
                  fontWeight: 'normal',
                  color: '#444',
                  marginTop: 8,
                  marginBottom: 20,
                  textAlign: 'left',
                }}
              >
                충청북도 청주시 청원구 오창읍 양청4길 45, 에이동 204-2호실
                (충북대학교 융합기술원)
              </h5>
              <h5 style={{ margin: 0, fontWeight: 'bold' }}>대표전화</h5>
              <h5
                style={{
                  margin: 0,
                  fontWeight: 'normal',
                  color: '#444',
                  marginTop: 8,
                  marginBottom: 20,
                }}
              >
                010 - 3504 - 5010
              </h5>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div
                id='map'
                style={{
                  width: getWindowDimensions().width * 0.9,
                  height: getWindowDimensions().width * 0.6,
                }}
              ></div>
            </div>
          </div>
        </div>

        <Footer base={'./'} history={history} type={3} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
      }}
    >
      <Header base={'./'} history={history} type={3} />
      {/* MAIN */}
      <div
        style={{
          minHeight: '8vh',
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '70%',
            height: getWindowDimensions().width * 0.2,
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            borderBottom: '2px solid #8777ad',
          }}
        >
          <h2 style={{ margin: 0, color: '#57477d', fontFamily: 'NexaBold' }}>
            Contact us
          </h2>
          <h5
            style={{
              color: '#666',
              fontWeight: 'bold',
              margin: 0,
              marginTop: 30,
              marginBottom: 30,
              fontFamily: 'NexaBold',
            }}
          >
            궁금하신 사항이 있으시면 문의 주세요. 자세하게 안내해드리겠습니다.
          </h5>
        </div>

        {/* INPUT FORM */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            minHeight: '50vh',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '3vh',
            paddingBottom: '3vh',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              paddingTop: '5vh',
              paddingBottom: '2vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                minWidth: 330,
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <h4 style={{ margin: 0, fontWeight: 'bold' }}>
                이름(기업 담당자)
              </h4>
              <InputBase
                id='name_input'
                // label="이름(기업 담당자)"
                value={help.name}
                onChange={nameChange}
                placeholder=''
                style={{
                  border: '1px solid #ddd',
                  borderRadius: 5,
                  width: '90%',
                  textDecoration: 'none',
                  marginTop: 10,
                  marginBottom: 30,
                  padding: '5px 10px',
                }}
              />
              <h4 style={{ margin: 0, fontWeight: 'bold' }}>제목</h4>
              <InputBase
                id='title_input'
                // label="이름(기업 담당자)"
                value={help.title}
                onChange={titleChange}
                placeholder=''
                style={{
                  border: '1px solid #ddd',
                  borderRadius: 5,
                  width: '90%',
                  textDecoration: 'none',
                  marginTop: 10,
                  marginBottom: 30,
                  padding: '5px 10px',
                }}
              />
              <h4
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  fontFamily: 'NexaBold',
                }}
              >
                이메일
              </h4>
              <InputBase
                id='email_input'
                // label="이름(기업 담당자)"
                value={help.email}
                onChange={emailChange}
                placeholder=''
                style={{
                  border: '1px solid #ddd',
                  borderRadius: 5,
                  width: '90%',
                  textDecoration: 'none',
                  marginTop: 10,
                  marginBottom: 30,
                  padding: '5px 10px',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                minWidth: 330,
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <h4 style={{ margin: 0, fontWeight: 'bold' }}>문의 종류</h4>
              <FormControl
                className={classes.formControl}
                style={{
                  width: '90%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: 10,
                  marginBottom: 30,
                }}
              >
                <NativeSelect
                  id='demo-customized-select-native'
                  value={help.type}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                  style={{ width: '100%' }}
                >
                  <option aria-label='None' value='0' disabled>
                    문의종류를 선택해주세요.
                  </option>

                  {typeList.map((option, i) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </NativeSelect>
              </FormControl>

              <h4 style={{ margin: 0, fontWeight: 'bold' }}>문의 내용</h4>
              <InputBase
                id='standard-textarea'
                // label="이름(기업 담당자)"
                multiline
                value={help.contents}
                onChange={contentsChange}
                rows={8}
                rowsMax={8}
                placeholder=''
                style={{
                  border: '1px solid #ddd',
                  borderRadius: 5,
                  width: '90%',
                  textDecoration: 'none',
                  marginTop: 10,
                  marginBottom: 30,
                  padding: '5px 10px',
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'flex-end',
              flexDirection: 'column',
              marginBottom: '2vh',
            }}
          >
            <Button
              onClick={() => {
                uploadHandler();
              }}
              style={{
                border: '1px solid #57477d',
                borderRadius: 30,
                padding: '8px 40px',
                marginRight: 10,
                color: '#57477d',
                fontWeight: 'bold',
              }}
            >
              문의 하기
            </Button>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            width: '70%',
            height: getWindowDimensions().width * 0.12,
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            borderBottom: '2px solid #8777ad',
          }}
        >
          <h2 style={{ margin: 0, color: '#57477d', fontFamily: 'NexaBold' }}>
            Location
          </h2>
          <h5
            style={{
              color: '#666',
              fontWeight: 'bold',
              margin: 0,
              marginTop: 30,
              marginBottom: 30,
              fontFamily: 'NexaBold',
            }}
          >
            찾아오시는 길
          </h5>
        </div>

        {/* Location FORM */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            minHeight: '40vh',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row',
            paddingTop: '5vh',
            paddingBottom: '15vh',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              minWidth: '50%',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <h4 style={{ margin: 0, fontWeight: 'bold' }}>주소</h4>
            <h5
              style={{
                margin: 0,
                fontWeight: 'normal',
                color: '#444',
                marginTop: 10,
                marginBottom: 30,
                textAlign: 'left',
              }}
            >
              충청북도 청주시 청원구 오창읍 양청4길 45, 에이동 204-2호실
              (충북대학교 융합기술원)
            </h5>
            <h4 style={{ margin: 0, fontWeight: 'bold' }}>대표전화</h4>
            <h5
              style={{
                margin: 0,
                fontWeight: 'normal',
                color: '#444',
                marginTop: 10,
                marginBottom: 30,
              }}
            >
              010 - 3504 - 5010
            </h5>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div
              id='map'
              style={{
                width: getWindowDimensions().width * 0.35,
                height: getWindowDimensions().width * 0.2,
              }}
            ></div>
          </div>
        </div>
        {/* FOOTER */}

        <Footer history={history} base={'./'} />
      </div>
    </div>
  );
};

export default Contact;
