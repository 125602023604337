import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import useWindowSize from '../API/useWindowSize';
import Footer from './ITEM/Footer';
import Header from './ITEM/Header';

const useStyles = makeStyles((theme) => ({
  header: {
    height: '8vh',
    width: '90%',
    display: 'flex',
    position: 'fixed',
    top: '0',
    paddingLeft: '5%',
    paddingRight: '5%',
    zIndex: 5,
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
  dateTitle: {
    color: '#57477d',
    margin: 0,
    marginRight: 5,
    fontWeight: 'bold',
    width: 50,
    textAlign: 'left',
  },
  dateContents: {
    color: 'black',
    fontWeight: 'normal',
    margin: 0,
    // marginRight:30,
    width: '100%',
    textAlign: 'left',
  },
}));

const About = ({ history }) => {
  const { width } = useWindowSize();
  const SERVER_IMG_URL =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/';
  const logo =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/Color_GNB.png';
  const main =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/Main.png';
  const facebook =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/Facebook.png';
  const instagram =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/Instagram.png';
  const bg =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/BG.png';
  const whiteLogo =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/White_Mainbanner.png';
  const mureka =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/favi00.jpg';
  const mureka2 =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/favi01.jpg';
  const twenti =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/20.png';
  const up =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/Up.png';
  const sample =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/Sample.png';
  const footer =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/footer.png';

  const crew_list = [
    { name: '천미르 Meer Chun', title: '대표/CEO' },
    { name: '천호영 HoYoung Chun', title: 'COO' },
    { name: '정종운 JongUn Jeong', title: 'CTO' },
    { name: '박수현 SooHyun Park', title: 'CD/Creative Director' },
    { name: '천진아 Jina Cheon', title: 'Dev Team' },
  ];
  const news_list = [
    { title: "대한민국 음악 큐레이션계의 '트랜드세터'", date: '2021. 04. 05' },
    {
      title: '[히든 챔피언] 천미르 아케인 주식회사 대표',
      date: '2021. 04. 05',
    },
  ];

  const classes = useStyles();
  const [count, setCount] = useState(1);
  const controlsProps = {
    style: {
      left: '50%',
      top: 10,
      position: 'fixed',
      transform: 'translateX(-30%)',
    },
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  if (
    width < 500 ||
    getWindowDimensions().width < 500 ||
    getWindowDimensions().width <= getWindowDimensions().height
  ) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Header base={'./'} history={history} type={1} />

        {/* mobile main */}
        <div
          style={{
            display: 'block',
            height: '88vh',
            width: '100%',
            flexDirection: 'column',
            overflow: 'scroll',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '80%',
              height: getWindowDimensions().width * 0.6115,
              paddingLeft: '10%',
              paddingRight: '10%',
              backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/About/main.png')`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <h3
              style={{
                margin: 0,
                marginTop: '3vh',
                color: 'white',
                textShadow: '1px 1px rgba(0,0,0,0.3)',
              }}
            >
              일상속에서 재현되는{' '}
            </h3>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h3
                style={{
                  margin: 0,
                  color: 'white',
                  textShadow: '1px 1px rgba(0,0,0,0.3)',
                  marginRight: 10,
                }}
              >
                나만의 예술 플랫폼,{' '}
              </h3>
            </div>
            <h3
              style={{
                margin: 0,
                color: '#57477d',
                textShadow: '1px 1px rgba(255,255,255,0.5)',
              }}
            >
              아케인
            </h3>

            <a
              href='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/v3/arcane_inform.pdf'
              target='_blank'
              style={{
                marginTop: 20,
                padding: '9px 15px',
                borderRadius: 30,
                backgroundColor: 'white',
                textDecoration: 'none',
                color: '#57477d',
                fontSize: 12,
                fontWeight: 'normal',
              }}
            >
              회사 소개 PDF
            </a>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              width: '80%',
              padding: '0 10%',
              marginTop: '2vh',
            }}
          >
            <h5
              style={{
                margin: 0,
                marginTop: 10,
                color: '#444',
                textShadow: '0px 0px rgba(0,0,0,0.2)',
                fontFamily: 'NexaBold',
              }}
            >
              TECH + ART
            </h5>
            <h6
              style={{
                margin: 0,
                marginTop: 10,
                color: '#444',
                textShadow: '0px 0px rgba(0,0,0,0.2)',
                fontWeight: 'normal',
              }}
            >
              아케인은 IT기술로 현실화된 '예술 플랫폼의 새로운 시작'입니다.
            </h6>
            <h6
              style={{
                margin: 0,
                marginTop: 0,
                color: '#444',
                textShadow: '0px 0px rgba(0,0,0,0.2)',
                fontWeight: 'normal',
              }}
            >
              사용자 중심의 혁신적인 기술을 통해 더 가까워진 예술을 만나보세요.
            </h6>
          </div>

          {/* ABOUT ARCANE */}
          <div
            style={{
              display: 'flex',
              width: '80%',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '3vh 10%',
            }}
          >
            <h2 style={{ color: '#57477d', fontFamily: 'NexaBold' }}>
              History
            </h2>

            <div
              style={{
                display: 'flex',
                minWidth: '60%',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                paddingTop: '2vh',
              }}
            >
              <h4
                style={{ margin: 0, marginBottom: 10, fontFamily: 'NexaBold' }}
              >
                2021
              </h4>
              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>08.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5
                      className={classes.dateContents}
                      style={{ marginBottom: 0 }}
                    >
                      2021 도전! K-스타트업 본선 진출
                    </h5>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>06.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5
                      className={classes.dateContents}
                      style={{ marginBottom: 5 }}
                    >
                      데이터바우처 지원사업 수요기업 선정
                    </h5>
                    <h5 className={classes.dateContents}>
                      2021 학생창업유망팀300 선정
                    </h5>
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>05.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5
                      className={classes.dateContents}
                      style={{ marginBottom: 5 }}
                    >
                      예비창업패키지 우수팀 선정
                    </h5>
                    <h5 className={classes.dateContents}>
                      경북대학교 국가근로 인턴기업 선정
                    </h5>
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>04.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5 className={classes.dateContents}>
                      사회적기업 성장지원센터 입주기업 선정
                    </h5>
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: 30,
                  }}
                >
                  <h5 className={classes.dateTitle}>03.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5
                      className={classes.dateContents}
                      style={{ marginBottom: 5 }}
                    >
                      시드투자 유치
                    </h5>
                    <h5
                      className={classes.dateContents}
                      style={{ marginBottom: 5 }}
                    >
                      경북대학교 창업동아리 재선정
                    </h5>
                    <h5 className={classes.dateContents}>
                      파비(FAVI) 앱 서비스 론칭
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                paddingBottom: '5vh',
              }}
            >
              <h4
                style={{ margin: 0, marginBottom: 10, fontFamily: 'NexaBold' }}
              >
                2020
              </h4>
              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>12.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5 className={classes.dateContents}>
                      전북 콘텐츠진흥원 콘텐츠기업
                    </h5>
                    <h5 className={classes.dateContents}>
                      창업활성화 지원 프로그램 선정
                    </h5>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>11.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5 className={classes.dateContents}>
                      아케인(주) 법인 설립
                    </h5>
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>09.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5 className={classes.dateContents}>
                      예비 창업자 패키지 선정
                    </h5>
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>07.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5 className={classes.dateContents}>
                      KNU 창업 아이디어 경진대회 장려상
                    </h5>
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 25,
                  }}
                >
                  <h5 className={classes.dateTitle}>05.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5 className={classes.dateContents}>
                      경북대학교 창업 역량 대회 장려상
                    </h5>
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: 0,
                  }}
                >
                  <h5 className={classes.dateTitle}>04.</h5>
                  <div
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <h5 className={classes.dateContents}>
                      경북대학교 창업동아리 선정
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* CREW */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 'auto',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f9faff',
              paddingTop: '5vh',
              paddingBottom: '5vh',
            }}
          >
            <h3
              style={{
                margin: 0,
                color: '#57477d',
                marginBottom: 10,
                fontFamily: 'NexaBold',
              }}
            >
              Arcane Crew
            </h3>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginTop: '3vh',
                marginBottom: '0vh',
              }}
            >
              {crew_list.map((item, i) => {
                const item_width = getWindowDimensions().width / 2;
                return (
                  <div
                    style={{
                      display: 'flex',
                      width: item_width,
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <img
                      src={`${SERVER_IMG_URL}ARCANE_WEB/v3/About/crew${
                        i + 1
                      }.png`}
                      style={{ width: 'auto', height: '4em' }}
                    />
                    <h6 style={{ margin: 0, marginTop: 10 }}>{item.name}</h6>
                    <h6
                      style={{
                        margin: 0,
                        color: '#666',
                        marginTop: 5,
                        marginBottom: 15,
                        fontWeight: 'normal',
                      }}
                    >
                      {item.title}
                    </h6>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Partners */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: '3vh',
              paddingBottom: '3vh',
              marginBottom: '5vh',
            }}
          >
            <h3 style={{ color: '#57477d', fontFamily: 'NexaBold' }}>
              Partners
            </h3>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '2vh',
                paddingBottom: '3vh',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <h5 style={{ margin: 0, color: '#444' }}>
                  아케인과 함께 새로운 문화/예술 생태계를
                </h5>
                <h5 style={{ margin: 0, marginTop: 5, color: '#444' }}>
                  만들어갈 파트너사를 찾고 있습니다.
                </h5>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: '5vh',
                }}
              >
                <img
                  src={`${SERVER_IMG_URL}ARCANE_WEB/v3/About/partner1.png`}
                  style={{ width: 'auto', height: '3.5em' }}
                />

                <img
                  src={`${SERVER_IMG_URL}ARCANE_WEB/v3/About/musia2.JPG`}
                  style={{ width: 'auto', height: '4em' }}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer base={'./'} history={history} type={1} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
      }}
    >
      <Header base={'./'} history={history} type={1} />
      {/* MAIN */}
      <div
        style={{
          marginTop: '8vh',
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '70%',
            height: getWindowDimensions().width * 0.5115 * 0.9,
            paddingLeft: '15%',
            paddingRight: '15%',
            backgroundImage: `url('${SERVER_IMG_URL}ARCANE_WEB/v3/About/main.png')`,
            backgroundPosition: 'center left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <h1
            style={{
              margin: 0,
              color: 'white',
              textShadow: '1px 1px rgba(0,0,0,0.3)',
            }}
          >
            일상속에서 재현되는{' '}
          </h1>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h1
              style={{
                margin: 0,
                color: 'white',
                textShadow: '1px 1px rgba(0,0,0,0.3)',
                marginRight: 10,
              }}
            >
              나만의 예술 플랫폼,{' '}
            </h1>
            <h1
              style={{
                margin: 0,
                color: '#57477d',
                textShadow: '1px 1px rgba(255,255,255,0.5)',
              }}
            >
              아케인
            </h1>
          </div>
          <h4
            style={{
              margin: 0,
              marginTop: 20,
              color: 'white',
              textShadow: '1px 1px rgba(0,0,0,0.2)',
              fontFamily: 'NexaBold',
            }}
          >
            TECH + ART
          </h4>
          <h4
            style={{
              margin: 0,
              marginTop: 20,
              color: 'white',
              textShadow: '1px 1px rgba(0,0,0,0.2)',
            }}
          >
            아케인은 IT기술로 현실화된 '예술 플랫폼의 새로운 시작'입니다.
          </h4>
          <h4
            style={{
              margin: 0,
              marginTop: 0,
              color: 'white',
              textShadow: '1px 1px rgba(0,0,0,0.2)',
            }}
          >
            사용자 중심의 혁신적인 기술을 통해 더 가까워진 예술을 만나보세요.
          </h4>
          <a
            href='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/v3/arcane_inform.pdf'
            target='_blank'
            style={{
              marginTop: 30,
              padding: '12px 23px',
              borderRadius: 30,
              backgroundColor: 'white',
              textDecoration: 'none',
              color: '#57477d',
              fontSize: 13,
            }}
          >
            회사 소개 PDF
          </a>
        </div>
        {/* ABOUT ARCANE */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '5vh',
            paddingBottom: '5vh',
          }}
        >
          <h2 style={{ color: '#57477d', fontFamily: 'NexaBold' }}>History</h2>
          <h5 style={{ margin: 0, fontWeight: 'normal', color: '#666' }}>
            IT기술과 예술의 융합, 아케인(주)가 이끌어 갑니다.
          </h5>
          <div
            style={{
              display: 'flex',
              minWidth: '70%',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingTop: '10vh',
            }}
          >
            <h4 style={{ margin: 0, width: 70, fontFamily: 'NexaBold' }}>
              2021
            </h4>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                marginLeft: 10,
                paddingLeft: 30,
                borderLeft: '1px solid #bbb',
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}>08.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5
                    className={classes.dateContents}
                    style={{ marginBottom: 0 }}
                  >
                    2021 도전! K-스타트업 본선 진출
                  </h5>
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}>06.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5
                    className={classes.dateContents}
                    style={{ marginBottom: 5 }}
                  >
                    데이터바우처 지원사업 수요기업 선정
                  </h5>
                  <h5 className={classes.dateContents}>
                    2021 학생창업유망팀300 선정
                  </h5>
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}>05.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5
                    className={classes.dateContents}
                    style={{ marginBottom: 5 }}
                  >
                    예비창업패키지 우수팀 선정
                  </h5>
                  <h5 className={classes.dateContents}>
                    경북대학교 국가근로 인턴기업 선정
                  </h5>
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}>04.</h5>
                <h5 className={classes.dateContents}>
                  사회적기업 성장지원센터 입주기업 선정
                </h5>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: 30,
                }}
              >
                <h5 className={classes.dateTitle}>03.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5
                    className={classes.dateContents}
                    style={{ marginBottom: 5 }}
                  >
                    시드투자 유치
                  </h5>
                  <h5
                    className={classes.dateContents}
                    style={{ marginBottom: 5 }}
                  >
                    경북대학교 창업동아리 재선정
                  </h5>
                  <h5 className={classes.dateContents}>
                    파비(FAVI) 앱 서비스 론칭
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              minWidth: '70%',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingBottom: '10vh',
            }}
          >
            <h4 style={{ margin: 0, width: 70, fontFamily: 'NexaBold' }}>
              2020
            </h4>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                marginLeft: 10,
                paddingLeft: 30,
                marginBottom: 15,
                borderLeft: '1px solid #bbb',
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}> 12. </h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5 className={classes.dateContents}>
                    전북 콘텐츠진흥원 콘텐츠기업 창업활성화 지원 프로그램 선정
                  </h5>
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}>11.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5 className={classes.dateContents}>아케인(주) 법인 설립</h5>
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}>09.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5 className={classes.dateContents}>
                    예비 창업자 패키지 선정
                  </h5>
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}>07.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5 className={classes.dateContents}>
                    KNU 창업 아이디어 경진대회 장려상
                  </h5>
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 25,
                }}
              >
                <h5 className={classes.dateTitle}>05.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5 className={classes.dateContents}>
                    경북대학교 창업 역량 대회 장려상
                  </h5>
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginBottom: 0,
                }}
              >
                <h5 className={classes.dateTitle}>04.</h5>
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <h5 className={classes.dateContents}>
                    경북대학교 창업동아리 선정
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CREW */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            height: 'auto',
            paddingLeft: '15%',
            paddingRight: '15%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f9faff',
            paddingTop: '5vh',
            paddingBottom: '5vh',
          }}
        >
          <h2
            style={{
              margin: 0,
              color: '#57477d',
              marginBottom: 10,
              fontFamily: 'NexaBold',
            }}
          >
            Arcane Crew
          </h2>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '5vh',
              marginBottom: '5vh',
            }}
          >
            {crew_list.map((item, i) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <img
                    src={`${SERVER_IMG_URL}ARCANE_WEB/v3/About/crew${
                      i + 1
                    }.png`}
                    style={{ width: '45%', height: 'auto' }}
                  />
                  <h5 style={{ margin: 0, marginTop: 10 }}>{item.name}</h5>
                  <h5
                    style={{
                      margin: 0,
                      color: '#666',
                      marginTop: 10,
                      fontWeight: 'normal',
                    }}
                  >
                    {item.title}
                  </h5>
                </div>
              );
            })}
          </div>
        </div>
        {/* Partners */}
        <div
          style={{
            display: 'flex',
            width: '70%',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '3vh',
            paddingBottom: '3vh',
          }}
        >
          <h2 style={{ color: '#57477d', fontFamily: 'NexaBold' }}>Partners</h2>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: '5vh',
              paddingBottom: '9vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <h4 style={{ margin: 0, color: '#444', fontFamily: 'NexaBold' }}>
                아케인과 함께 새로운 문화/예술 생태계를
              </h4>
              <h4
                style={{
                  margin: 0,
                  marginTop: 5,
                  color: '#444',
                  fontFamily: 'NexaBold',
                }}
              >
                만들어갈 파트너사를 찾고 있습니다.
              </h4>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/About/partner1.png`}
                style={{ width: 'auto', height: '5em' }}
              />
              <img
                src={`${SERVER_IMG_URL}ARCANE_WEB/v3/About/musia2.JPG`}
                style={{ width: 'auto', height: '7em' }}
              />
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <Footer history={history} base={'./'} />
      </div>
    </div>
  );
};

export default About;
