import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Header } from '../../component/common';
import Footer from '../../component/common/Footer';

export default function NewsInfo() {
  const location = useLocation();
  const state = location.state;
  const SERVER_IMG_URL =
    'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/';
  const [news, setNews] = useState({
    date: '',
    titleEn: '',
    titleKo: '',
    contentsEn: '',
    contentsKo: '',
    path: '',
    idx: 0,
    coverUrl: '',
    news: '',
  });

  useEffect(() => {
    if (state) {
      try {
        setNews({ ...state });
      } catch (error) {}
    }
  }, [state]);

  const handleBack = () => {
    window.history.back();
  };

  return (
    <main className='column Main NewsInfo'>
      <Header />
      <section className='column NewsInfo__main'>
        <div className='column NewsInfo__header'>
          <h5>{`Press Release ${news.date}`}</h5>
          <h3>{news.titleEn}</h3>
        </div>
        <img
          className='NewsInfo__cover'
          // src={SERVER_IMG_URL + news.url}
          src={news.coverUrl}
          alt='cover'
        />
        <div className='column NewsInfo__footer'>
          <span className='NewsInfo__contents'>{news.contentsEn}</span>
          <span className='NewsInfo__news'>{`NEWS REPORT ${news.news}`}</span>
        </div>
        <button type='button' className='NewsInfo__button' onClick={handleBack}>
          LIST VIEW
        </button>
      </section>
      <Footer />
    </main>
  );
}
