export default function CrewProfile(props) {
  const { name, position, description, profileUrl, isCeo = false } = props;

  const profileError = (e) => {
    try {
      e.currentTarget.className += ' CrewBox__profile--error';
      e.currentTarget.src =
        'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_ICON/v2/dark/person.png';
    } catch (error) {}
  };

  if (isCeo) {
    return (
      <div className='CrewBox CrewBox--ceo'>
        <img className='CrewBox__profile' src={profileUrl} alt='profile' />
        <div className='column CrewBox__box'>
          <span className='CrewBox__team'>{position}</span>
          <span className='CrewBox__name'>{name}</span>
          <span className='CrewBox__description'>{description}</span>
        </div>
      </div>
    );
  }

  return (
    <div className='column CrewBox__box'>
      <img
        className='CrewBox__profile'
        src={profileUrl}
        alt='profile'
        onError={profileError}
      />
      <span className='CrewBox__position'>{position}</span>
      <span className='CrewBox__name'>{name}</span>
    </div>
  );
}
