import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Header } from '../../component/common';
import Footer from '../../component/common/Footer';
import { Chart, Favi, Main } from '../../component/service';
import '../../scss/service.scss';

export default function Service() {
  return (
    <main className='column Main Service'>
      <Header />
      {/* 광고 영상 */}
      {/* <article className='column Service__header'>
        <video
          id='favi_video'
          className='ServiceFavi__video'
          autoPlay
          loop
          muted
        >
          <source
            type='video/mp4'
            src='https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/FAVI_VIDEO/favi_ad.mp4'
          ></source>
        </video>
        <div className='column Service__box'>
          <h1>
            DO <strong>THE</strong> DIGGIN<strong>'</strong>
          </h1>
        </div>
      </article> */}
      <section
        className='column Service__header'
        style={{
          backgroundImage:
            'url("https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/Service__banner.webp")',
        }}
      >
        <div className='column Service__box'>
          <h1>
            DO <strong>THE</strong> DIGGIN<strong>'</strong>
          </h1>
        </div>
      </section>
      <Outlet />
      <Routes>
        <Route path='*' element={<Main />} />
        <Route path='FAVI' element={<Favi />} />
        <Route path='CHART' element={<Chart />} />
      </Routes>
      <Footer />
    </main>
  );
}
