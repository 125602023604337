
import React,{useState,useEffect} from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import {Redirect, Link, useParams } from "react-router-dom";
import { Avatar,Button,TextField,Card,CardMedia,CardContent,Input } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useWindowSize from "../../API/useWindowSize";
// import {GetCharactor,GetTag,GetCategory,MusicApi} from '../../api/lib'

const useStyles = makeStyles((theme) => ({
   main:{
     width:'100%'
   },
   titleBar:{
     background:'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   title:{
     marginLeft:'5%'
   },
   fix:{
     position:'fixed',
     zIndex:10,
     top:0,
     left:0,
     flexDirection:'row',
     alignItems:'center',
     display:'flex',
     width:'100%',
     height:'10vh',
     boxShadow:'0px 2px 5px rgba(0,0,0,0.1)'
   },
   footer:{
     width:'80%',
     marginLeft:'10%',
     height:'35vh',
     paddingBottom:'15vh',
     display:'flex',
     alignItems:'flex-end',
     justifyContent:'center',
     backgroundColor: 'white',
     borderTop:'2px solid #444'
   },
   fix_right_txt:{
     marginLeft:15,
     fontSize:'1.1em',
     color:'white'
   },
   footer_sub2:{
     fontSize:'0.8em',
     textDecoration: 'none',
     color:'#444',
     fontWeight:'normal',
     margin:0,
     marginBottom:20
   },
   footer_sub3:{
     fontSize:'0.9em',
     textDecoration: 'none',
     color:'#444',
     fontWeight:'normal',
     marginTop:10,
     marginBottom:0
   },

}));

const Footer = ({history,base,type}) => {
const classes = useStyles();

const SERVER_API_URL = 'https://api.arcaneofficial.com/';
const SERVER_IMG_URL = 'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/'
const SERVER_PROFILE_URL = 'https://s3.ap-northeast-2.amazonaws.com/faviprofile/user/'
const logo = `${SERVER_IMG_URL}FAVI_ICON/logo_m_full.png`

const [color,setColor] = useState(['#BD95FF','#FFE06A','#6DF2E3','#FDAD4C','#48C0FF'])
const [level,setLevel] = useState(0)
const [link,setLink] = useState('./')
const [type2,setType2] = useState(0)
const [topArr,setTopArr] = useState([
  {
    en:'Home',
    kr:'홈',
    path:'./',
  },
  {
    en:'About',
    kr:'어바웃',
    path:'./About',
  },
  {
    en:'News',
    kr:'뉴스',
    path:'./News',
  },
  {
    en:'Contact',
    kr:'컨택',
    path:'./Contact',
  }
])
const { width } = useWindowSize();

  function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

useEffect(()=>{
  if (base !== null && base !== undefined) {
    setLink(base)
  }
  if (type !== null && type !== undefined){
    setType2(type)
  }

},[base,type])

if ( width<500 || getWindowDimensions().width <500 || getWindowDimensions().width <= getWindowDimensions().height ) {


  return(
    <div style={{display:'flex',width:'100%',height:'6vh',alignItems:'center',justifyContent:'center'}}>

    {
      topArr.map((item,i)=>{

        return(
          <Link
             to={{
               pathname: item.path,
               state: { fromDashboard: true }
             }}
             style={{textDecoration:'none',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',flex:1,background:(i == type2)?'white':'#eee',height:'100%'}}
           >
            <h6 style={{margin:0,color:(i == type2)?'#57477d':'#aaa',fontFamily:'NexaDemoBold',fontSize:13.5,borderBottom:(i == type2)?'1px solid #57477d':null}}>{item.en}</h6>
           </Link>
        )
      })
    }



    </div>
  )
}


  return(
    <div style={{display:'flex',width:'70%',paddingLeft:'15%',paddingRight:'15%',height:'15vh',backgroundColor:'#e4e4f8',flexDirection:'row',alignItems:'center'}}>
      <div style={{flex:1,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

        </div>

        <div style={{flexDirection:'row',alignItems:'center',display:'flex',marginTop:15}}>
        <h6 style={{margin:0,color:'#444',fontWeight:'normal',borderRight:'1px solid #888',paddingRight:10,fontSize:12}}>아케인 주식회사</h6>
          <h6 style={{margin:0,color:'#444',fontWeight:'normal',borderRight:'1px solid #888',paddingRight:10,fontSize:12,paddingLeft:10}}>070-8018-6802</h6>
          <h6 style={{margin:0,color:'#444',fontWeight:'normal',paddingLeft:10,fontSize:12}}>사업자등록번호 171-86-02100</h6>
        </div>
        <h6 style={{margin:0,color:'#444',fontWeight:'normal',marginTop:5,fontSize:12}}>충청북도 청주시 청원구 오창읍 양청4길 45, 에이동 204-2호실 (충북대학교 융합기술원)</h6>

      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end',justifyContent:'center'}}>
        <img src={`${SERVER_IMG_URL}ARCANE_WEB/v3/logo.png`} style={{width:'auto',height:'1.3em'}} />
        <h2 className={classes.footer_sub3} style={{display:'flex',fontSize:'0.7em',color:'#666',fontWeight:'normal'}}>Copyright © 2021 <strong style={{marginLeft:5,marginRight:5}}> arcane Inc. </strong> All rights reserved.</h2>
      </div>
    </div>
  );

}
export default React.memo(Footer);
