
import React,{useState,useEffect} from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import {Redirect, Link, useParams } from "react-router-dom";
import { Avatar,Button,TextField,Card,CardMedia,CardContent,Input } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useWindowSize from "../../API/useWindowSize";
// import {GetCharactor,GetTag,GetCategory,MusicApi} from '../../api/lib'

const useStyles = makeStyles((theme) => ({
   main:{
     width:'100%'
   },
   titleBar:{
     background:'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   title:{
     marginLeft:'5%'
   },
   fix:{
     position:'fixed',
     zIndex:10,
     top:0,
     left:0,
     flexDirection:'row',
     alignItems:'center',
     display:'flex',
     width:'100%',
     height:'10vh',
     boxShadow:'0px 2px 5px rgba(0,0,0,0.1)'
   },
   footer:{
     width:'80%',
     marginLeft:'10%',
     height:'35vh',
     paddingBottom:'15vh',
     display:'flex',
     alignItems:'flex-end',
     justifyContent:'center',
     backgroundColor: 'white',
     borderTop:'2px solid #444'
   },
   fix_right_txt:{
     marginLeft:15,
     fontSize:'1.1em',
     color:'white'
   },
   footer_sub2:{
     fontSize:'0.8em',
     textDecoration: 'none',
     color:'#444',
     fontWeight:'normal',
     margin:0,
     marginBottom:20
   },
   footer_sub3:{
     fontSize:'0.9em',
     textDecoration: 'none',
     color:'#444',
     fontWeight:'normal',
     marginTop:10,
     marginBottom:0
   },

}));

const Header = ({history,base,type}) => {
const classes = useStyles();

const SERVER_API_URL = 'https://api.arcaneofficial.com/';
const SERVER_IMG_URL = 'https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/'
const SERVER_PROFILE_URL = 'https://s3.ap-northeast-2.amazonaws.com/faviprofile/user/'
const logo = `${SERVER_IMG_URL}FAVI_ICON/logo_m_full.png`

const [color,setColor] = useState(['#BD95FF','#FFE06A','#6DF2E3','#FDAD4C','#48C0FF'])
const [level,setLevel] = useState(0)
const [link,setLink] = useState('./')
const [type2,setType2] = useState(0)

const [topArr,setTopArr] = useState([
  {
    en:'Home',
    kr:'홈',
    path:'./',
  },
  {
    en:'About Us',
    kr:'어바웃',
    path:'./About',
  },
  {
    en:'News',
    kr:'뉴스',
    path:'./News',
  },
  {
    en:'Contact Us',
    kr:'컨택',
    path:'./Contact',
  }
])
const { width } = useWindowSize();
  function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

useEffect(()=>{
  if (base !== null && base !== undefined) {
    setLink(base)
  }
  if (type !== null && type !== undefined){
    setType2(type)
  }

},[base,type])
if ( width<500 || getWindowDimensions().width <500 || getWindowDimensions().width <= getWindowDimensions().height ) {
  return (
    <div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',height:'6vh'}}>
      <div style={{flex:1,padding:'0px 5%',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',display:'flex',backgroundColor:'white'}}>
        <a href='./' style={{display:'contents'}}>
          <img src={`${SERVER_IMG_URL}ARCANE_WEB/v3/logo.png`} style={{width:'auto',height:'1.3em',margin:'15px 0px'}} />
        </a>
      </div>
      <a  style={{marginRight:20,textDecoration:'none',alignItems:'center',justifyContent:'center'}} target='_blank' href='https://www.facebook.com/Favi.Official' >
        <img src={`${SERVER_IMG_URL}ARCANE_WEB/v3/facebook.png`} style={{width:'auto',height:'1em'}} />
      </a>
      <a  style={{marginRight:20,textDecoration:'none',alignItems:'center',justifyContent:'center'}} target='_blank' href='https://www.instagram.com/official_fallinvibe/' >
        <img src={`${SERVER_IMG_URL}ARCANE_WEB/v3/insta.png`} style={{width:'auto',height:'0.95em'}} />
      </a>
    </div>
  );
}

  return(
    <div style={{position:'fixed',width:'70%',height:'8vh',paddingLeft:'15%',paddingRight:'15%',flexDirection:'row',alignItems:'center',display:'flex',backgroundColor:'white',zIndex:10}}>
      <a href='./' style={{display:'contents'}}>
        <img src={`${SERVER_IMG_URL}ARCANE_WEB/v3/logo.png`} style={{width:'auto',height:'1.6em'}} />
      </a>
      <div style={{display:'flex',flex:1,alignItems:'center',justifyContent:'flex-end'}}>
      {
        topArr.map((item,i)=>{

          return(
            <Link
               to={{
                 pathname: item.path,
                 state: { fromDashboard: true }
               }}
               style={{textDecoration:'none',display:'flex',flexDirection:'row',alignItems:'center',marginRight:35,}}
             >
              <h5 style={{margin:0,color:(i == type2)?'#57477d':'#888',fontFamily:'NexaDemoBold'}}>{item.en}</h5>
             </Link>
          )
        })
      }
      {
         // <Link
         //   to={{
         //     pathname: "./",
         //     state: { fromDashboard: true }
         //   }}
         //   style={{textDecoration:'none',display:'flex',flexDirection:'row',alignItems:'center',marginRight:35,}}
         // >
         //  <h5 style={{margin:0,color:'#666'}}>Home</h5>
         // </Link>
         // <Link
         //    to={{
         //      pathname: "/About",
         //      state: { fromDashboard: true }
         //    }}
         //    style={{textDecoration:'none',display:'flex',flexDirection:'row',alignItems:'center',marginRight:35,}}
         //  >
         //   <h5 style={{margin:0,color:'#666'}}>About Us</h5>
         //  </Link>
         //  <Link
         //     to={{
         //       pathname: "/News",
         //       state: { fromDashboard: true }
         //     }}
         //     style={{textDecoration:'none',display:'flex',flexDirection:'row',alignItems:'center',marginRight:35,}}
         //   >
         //    <h5 style={{margin:0,color:'#57477d'}}>News</h5>
         //   </Link>
         //  {
         //    <Link
         //      to={{
         //        pathname: "/Contact",
         //        state: { fromDashboard: true }
         //      }}
         //      style={{textDecoration:'none',display:'flex',flexDirection:'row',alignItems:'center',marginRight:35,}}
         //    >
         //     <h5 style={{margin:0, color:'#666'}}>Contact Us</h5>
         //    </Link>
         //    // <a
         //    //   href='mailto:official.arcane.inc@gmail.com'
         //    //   style={{textDecoration:'none',display:'flex',flexDirection:'row',alignItems:'center',marginRight:35,}}
         //    // >
         //    //  <h5 style={{margin:0, color:'#666'}}>Contact Us</h5>
         //    // </a>
         //  }
}
          <a  style={{marginRight:25,textDecoration:'none',alignItems:'center',justifyContent:'center'}} target='_blank' href='https://www.facebook.com/Favi.Official' >
            <img src={`${SERVER_IMG_URL}ARCANE_WEB/v3/facebook.png`} style={{width:'auto',height:'1.15em'}} />
          </a>
          <a  style={{textDecoration:'none',alignItems:'center',justifyContent:'center'}} target='_blank' href='https://www.instagram.com/official_fallinvibe/' >
            <img src={`${SERVER_IMG_URL}ARCANE_WEB/v3/insta.png`} style={{width:'auto',height:'1.1em'}} />
          </a>
      </div>
    </div>
  );

}
export default React.memo(Header);
