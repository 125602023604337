import { NavLink } from 'react-router-dom';

export default function PartnerCard(props) {
  const { enterprise, description, image, path } = props;

  return (
    <NavLink
      to={path}
      target='_blank'
      type='button'
      key={`partner__${enterprise}`}
      className='column PartnerCard'
    >
      <img
        className='PartnerCard__cover'
        alt='cover'
        src={`https://s3.ap-northeast-2.amazonaws.com/acanegallery.com/ARCANE_WEB/renewal/${image}.webp`}
      />
      <h5 className='PartnerCard__name'>{enterprise}</h5>
      <span className='PartnerCard__description'>{description}</span>
    </NavLink>
  );
}
